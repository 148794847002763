import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { GetMealDishPreview, GetWorkoutPreview, Tasks_RemoveStatus, Tasks_RemoveTask, Tasks_ReorderStatuses, Tasks_ReorderTasks } from "../../../Services/Actions";
import moment from "moment";
import history from '../../../history';
import Popover from "react-popover";
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import { AddButtonImage, ClockIcon, DayHeaderContainer, DurationContainer, EventContainer, EventImageContainer, EventNameContainer, InlineTexts, MultiSelectCheckmark, OverlayTextContainer, SectionContainer, StyledLoadingCircle, StyledHR, ToggleShadow, WeekContainer, WeekNumberBar, WeeklyDay, WeeklyEventsContainer, WeeklyViewContainer, WorkoutDetailContainer, WorkoutDetailsContainer } from "./styles";
import { PopoverContent, ReorderIcon, StyledPopover, TaskStatusDraggableContainer } from "./styles";
import * as styles from './styles';

import ButtonGroup from '../../Buttons/ButtonGroup';
import { Icon as IconComponent } from '../../IconLibrary';
import * as IconsList from '../../IconLibrary';
import Image from "../../Image";
import Loading from "../../Loading";
import MultiText from "../../Text/MultiText";
import NutritionPreview from "./NutritionPreview";
import PageText from "../../Text/PageText";
import Spacer from "../../Spacer";

import Info from '../../../Modals/Info';
import NutritionFacts from "../../../Modals/NutritionFacts";
import NutritionPreviewFilters from "../../../Modals/NutritionPreviewFilters";

import Clock from "../../../Assets/Icons/Clock.png";
import { ReactComponent as GarminSVG } from '../../../Assets/SVG/Garmin.svg';
import NoImage from "../../../Assets/Icons/NoImage.png";
import Plus from "../../../Assets/Icons/PlusSign.png";
import Reorder from '../../../Assets/Icons/Reorder_Dark.png';
import VideoCall from "./VideoCall";
import { getDateDifference } from "../../../Functions";
import { Completed, Today, Upcoming } from "../../../Utils/constants";

const HOVER_DELAY = 500;

class DailyDish extends React.Component {
  state = { delayHandler: null, DishPreview: null, PopoverIsOpen: false };

  componentDidUpdate = () => {
    if (
      this.props.CloseOtherMealPopovers !== null &&
      this.props.CloseOtherMealPopovers !== this.props.uId &&
      this.state.PopoverIsOpen
    )
      this.setState({ PopoverIsOpen: false });
  };

  handleMouseEnter = () => {
    if (!this.state.PopoverIsOpen) {
      this.setState({
        delayHandler: setTimeout(() => {
          this.onTogglePopover();
          this.setState({ delayHandler: null });
        }, HOVER_DELAY),
      });
    }
  };

  handleMouseLeave = () => {
    // if (this.state.PopoverIsOpen) this.setState({ delayHandler: null, PopoverIsOpen: false });
    // else
    this.setState({ delayHandler: null });

    clearTimeout(this.state.delayHandler);
  };

  onTogglePopover = () => {
    var { uId } = this.props;
    var { DishPreview } = this.state;

    this.setState({ PopoverIsOpen: !this.state.PopoverIsOpen }, () => {
      if (!!this.state.PopoverIsOpen) this.props.CloseOtherPopovers(uId);
      if (!!this.state.PopoverIsOpen && !DishPreview)
        this.props
          .GetMealDishPreview({ uId })
          .then(({ DishPreview }) => this.setState({ DishPreview }));
    });
  };

  renderPopoverContent = () => {
    var { TryingGetMealDishPreview } = this.props;
    var { DishPreview } = this.state;

    return (
      <StyledPopover>
        <PopoverContent>
          {TryingGetMealDishPreview || !DishPreview ? (
            <i className="fas fa-spinner fa-spin"></i>
          ) : (
            <>
              <PageText
                FontFamily="medium-italic"
                FontSize="small"
                JustifyContent="center"
                NoMargin
                Text={`${DishPreview.Calories} kcal`}
                TextAlign="center"
              />

              <Spacer Size="small" />

              {DishPreview.Ingredients.map(
                ({ FoodId, FoodName, Quantity, UnitName }) => {
                  return (
                    <React.Fragment key={FoodId}>
                      <MultiText
                        Texts={[
                          {
                            FontFamily: "bold",
                            FontSize: "small",
                            Text: `${FoodName}: `,
                          },
                          {
                            FontFamily: "medium",
                            FontSize: "small",
                            Text: `${Quantity} ${UnitName}`,
                          },
                        ]}
                      />
                    </React.Fragment>
                  );
                }
              )}
            </>
          )}
        </PopoverContent>
      </StyledPopover>
    );
  };

  render() {
    var {
      dataLogFoodDetail,
      DishName,
      DishType,
      DishUrl,
      IsCompleted_Meal,
      IsCurrentMonth,
      MultiSelect,
      onMultiSelectMeal,
      SelectedMeals,
      uId,
    } = this.props;

    const popoverProps = {
      isOpen: this.state.PopoverIsOpen,
      preferPlace: "above",
      onOuterAction: this.onTogglePopover,
      body: this.renderPopoverContent(),
      tipSize: 10,
    };

    return (
      <Popover {...popoverProps}>
        <EventContainer
          IsCurrentMonth={IsCurrentMonth}
          Toggleable={!!MultiSelect}
          onClick={
            MultiSelect ? (event) => onMultiSelectMeal(event, uId) : null
          }
          onMouseEnter={!MultiSelect ? this.handleMouseEnter : null}
          onMouseLeave={!MultiSelect ? this.handleMouseLeave : null}
        >
          {DishType === "MealPlan" && (
            <>
              <EventImageContainer
                completed={IsCompleted_Meal}
                to={`/meal/${uId}`}
              >
                <Image
                  Alt="Dish"
                  ImageSrc={DishUrl}
                  LoadingImageSrc={NoImage}
                  SelfLoading
                  ShowOverlay
                />

                {this.state.delayHandler && <StyledLoadingCircle />}
              </EventImageContainer>

              <Spacer Size="extra-extra-small" />

              <EventNameContainer
                completed={IsCompleted_Meal}
                to={`/meal/${uId}`}
              >
                <PageText
                  FontFamily="medium"
                  FontSize="medium-1"
                  JustifyContent="center"
                  NoMargin
                  Text={DishName}
                  TextAlign="center"
                />
              </EventNameContainer>
            </>
          )}

          {DishType === "LogFood" && (
            <>
              <EventNameContainer
                completed={IsCompleted_Meal}
                to={`/meal/${uId}`}
              >
                {dataLogFoodDetail.map(
                  ({ FoodId, FoodName, Quantity, UnitName }, index2) => {
                    var IsMoreFoods =
                      index2 < dataLogFoodDetail.length - 1 ? 1 : 0;

                    return (
                      <React.Fragment key={FoodId}>
                        <PageText
                          FontSize="medium-1"
                          JustifyContent="center"
                          NoMargin
                          Text={FoodName}
                          TextAlign="center"
                        />

                        <Spacer Size="extra-extra-small" />

                        <PageText
                          FontFamily="medium"
                          FontSize="medium-1"
                          JustifyContent="center"
                          NoMargin
                          Text={`${Quantity} ${UnitName}`}
                          TextAlign="center"
                        />

                        {!!IsMoreFoods && <Spacer Size="small" />}
                      </React.Fragment>
                    );
                  }
                )}

                {this.state.delayHandler && <StyledLoadingCircle />}
              </EventNameContainer>
            </>
          )}

          {!!MultiSelect && (
            <ToggleShadow Selected={SelectedMeals.indexOf(+uId) !== -1} />
          )}
          {!!MultiSelect && (
            <MultiSelectCheckmark
              Selected={SelectedMeals.indexOf(+uId) !== -1}
            />
          )}
        </EventContainer>
      </Popover>
    );
  }
}

class DailyWorkout extends React.Component {
  state = { delayHandler: null, WorkoutPreview: null, PopoverIsOpen: false };

  componentDidUpdate = () => {
    if (
      this.props.CloseOtherWorkoutPopovers !== null &&
      this.props.CloseOtherWorkoutPopovers !== this.props.WorkoutId &&
      this.state.PopoverIsOpen
    )
      this.setState({ PopoverIsOpen: false });
  };

  handleMouseEnter = () => {
    if (!this.state.PopoverIsOpen) {
      this.setState({
        delayHandler: setTimeout(() => {
          this.onTogglePopover();
          this.setState({ delayHandler: null });
        }, HOVER_DELAY),
      });
    }
  };

  handleMouseLeave = () => {
    // if (this.state.PopoverIsOpen) this.setState({ delayHandler: null, PopoverIsOpen: false });
    // else
    this.setState({ delayHandler: null });

    clearTimeout(this.state.delayHandler);
  };

  onTogglePopover = () => {
    var { WorkoutId } = this.props;
    var { WorkoutPreview } = this.state;

    this.setState({ PopoverIsOpen: !this.state.PopoverIsOpen }, () => {
      if (!!this.state.PopoverIsOpen) this.props.CloseOtherPopovers(WorkoutId);
      if (!!this.state.PopoverIsOpen && !WorkoutPreview)
        this.props
          .GetWorkoutPreview({ WorkoutId })
          .then(({ WorkoutPreview }) => this.setState({ WorkoutPreview }));
    });
  };

  renderPopoverContent = () => {
    var { TryingGetWorkoutPreview } = this.props;
    var { WorkoutPreview } = this.state;

    return (
      <StyledPopover>
        <PopoverContent>
          {TryingGetWorkoutPreview || !WorkoutPreview ? (
            <i className="fas fa-spinner fa-spin"></i>
          ) : (
            <>
              <PageText
                FontFamily="medium-italic"
                FontSize="small"
                JustifyContent="center"
                NoMargin
                Text={`${WorkoutPreview.Calories} kcal`}
                TextAlign="center"
              />

              <Spacer Size="small" />

              <pre>{JSON.stringify(WorkoutPreview, null, 2)}</pre>

              {/* {
                                WorkoutPreview.Ingredients.map(({ FoodId, FoodName, Quantity, UnitName }) => {
                                    return (
                                        <React.Fragment key={FoodId}>
                                            <MultiText
                                                Texts={[
                                                    { FontFamily: 'bold', FontSize: 'small', Text: `${FoodName}: ` },
                                                    { FontFamily: 'medium', FontSize: 'small', Text: `${Quantity} ${UnitName}` },
                                                ]}
                                            />
                                        </React.Fragment>
                                    );
                                })
                            } */}
            </>
          )}
        </PopoverContent>
      </StyledPopover>
    );
  };

  render() {
    var { t } = this.props;
    var {
      AssignedDate,
      Completed,
      Duration,
      IsCurrentMonth,
      MultiSelect,
      onMultiSelectWorkout,
      PreviewData,
      SelectedWorkouts,
      ShowGarmin,
      ShowWorkoutImage,
      ShowWorkoutPreview,
      ShowWorkoutTime,
      SourceId,
      SurveyData,
      WorkoutId,
      WorkoutImage,
      WorkoutLocation,
      WorkoutName,
      WorkoutType
    } = this.props;

    var IsCompleted_Workout =
      +Completed === 1
        ? "complete"
        : +Completed > 0
        ? "partial"
        : moment(AssignedDate.substring(0, 10)).format("YYYY-MM-DD") <
          moment().format("YYYY-MM-DD")
        ? "incomplete"
        : "";

    var OverlayText = "";
    if (ShowWorkoutTime) OverlayText = AssignedDate.substring(11, 16);
    if (ShowWorkoutTime && WorkoutLocation) OverlayText += ` - `;
    if (WorkoutLocation) OverlayText += `${WorkoutLocation}`;

    const popoverProps = {
      isOpen: this.state.PopoverIsOpen,
      preferPlace: "above",
      onOuterAction: this.onTogglePopover,
      body: this.renderPopoverContent(),
      tipSize: 10,
    };

    return (
      <Popover {...popoverProps}>
        <EventContainer
          IsCurrentMonth={IsCurrentMonth}
          Toggleable={!!MultiSelect}
          onClick={
            MultiSelect
              ? (event) => onMultiSelectWorkout(event, WorkoutId)
              : null
          }
          // onMouseEnter={!MultiSelect ? this.handleMouseEnter : null}
          // onMouseLeave={!MultiSelect ? this.handleMouseLeave : null}
        >
          <WorkoutDetailContainer
            className="WorkoutDetailContainer"
            completed={IsCompleted_Workout}
            to={`/workout/${WorkoutId}`}
          >
            {
              !!ShowWorkoutImage &&
              <>
                <Image
                  Alt="Workout"
                  ImageSrc={WorkoutImage || NoImage}
                  LoadingImageSrc={NoImage}
                  SelfLoading
                  ShowOverlay
                />

                {this.state.delayHandler && <StyledLoadingCircle />}

                <Spacer Size="extra-extra-small" />
              </>
            }

            <WorkoutDetailsContainer className="WorkoutDetailsContainer">
              <PageText
                FontFamily="medium"
                FontSize="medium-1"
                JustifyContent="center"
                NoMargin
                Text={WorkoutName}
                TextAlign="center"
              />
              
              <Spacer Size="extra-extra-small" />

              <DurationContainer>
                <ClockIcon src={Clock} alt="Duration" />

                <PageText
                  ElementType="span"
                  FontFamily="semibold"
                  FontSize="medium-1"
                  JustifyContent="center"
                  NoMargin
                  Text={`${Duration} ${t("min_str")}`}
                  TextAlign="center"
                />
              </DurationContainer>

              {
                !!OverlayText &&
                <>
                  <Spacer Size="extra-extra-small" />
                  
                  <PageText
                    FontFamily="medium-italic"
                    FontSize="small"
                    JustifyContent="center"
                    NoMargin
                    Text={OverlayText}
                    TextAlign="center"
                  />
                </>
              }

              {
                ShowGarmin &&
                <>
                  <Spacer Size="extra-extra-small" />
                  
                  <DurationContainer>
                    <GarminSVG />

                    <PageText
                      ElementType="span"
                      FontFamily="semibold"
                      FontSize="medium-1"
                      JustifyContent="center"
                      NoMargin
                      Text="Garmin"
                      TextAlign="center"
                    />
                  </DurationContainer>
                </>
              }
            </WorkoutDetailsContainer>

            {
              !!ShowWorkoutPreview && ((PreviewData && (WorkoutType === 'Gym' || WorkoutType === 'Endurance')) || !!SurveyData.length)
              &&
              <>
                <StyledHR />

                {
                  (PreviewData && (WorkoutType === 'Gym' || WorkoutType === 'Endurance')) ?
                  ((SourceId === 1 || SourceId === 2) && +Completed === 0) ?
                  <PageText
                    FontFamily="medium"
                    FontSize="small"
                    JustifyContent="flex-start"
                    NoMargin
                    Text={t('_workout_preview_from_algorithm')}
                    TextAlign="left"
                  />
                :
                  WorkoutType === 'Gym' ?
                  PreviewData.map(({ ExerciseName, NumSets }, index) => {
                    return <MultiText key={index} Texts={[{ FontFamily: 'semibold', FontSize: 'small', Text: `${NumSets} x` }, { FontFamily: 'medium', FontSize: 'small', Text: ExerciseName }]} />
                  })
                :
                  WorkoutType === 'Endurance' &&
                  PreviewData.map(({ NumLaps }, index) => {
                    NumLaps = +NumLaps;

                    if (NumLaps === 0) {
                      return <MultiText key={index} Texts={[{ FontFamily: 'medium', FontSize: 'small', Text: t('break') }]} />
                    }

                    return <MultiText key={index} Texts={[{ FontFamily: 'semibold', FontSize: 'small', Text: `${NumLaps} x` }, { FontFamily: 'medium', FontSize: 'small', Text: NumLaps === 1 ? t('_lap').toLowerCase() : t('_laps').toLowerCase() }]} />
                  })
                :
                  null
                }

                {
                  (PreviewData && (WorkoutType === 'Gym' || WorkoutType === 'Endurance')) && !!SurveyData.length &&
                  <Spacer Size="small" />
                }

                {
                  !!SurveyData.length &&
                  SurveyData.map(({ SurveyResponse, SurveyType }, SurveyIndex) => {
                    return <MultiText key={SurveyIndex} Texts={[{ FontFamily: 'semibold', FontSize: 'small', Text: `${t(`_mini_survey_types_${SurveyType.toLowerCase()}`)}:` }, { FontFamily: 'medium', FontSize: 'small', Text: !!+SurveyResponse ? `${SurveyResponse} ${SurveyType === 'Sleep' ? t('_hours').toLowerCase() : ' / 10'}` : 'N/A' }]} />
                  })
                }
              </>
            }
          </WorkoutDetailContainer>

          {!!MultiSelect && <ToggleShadow Selected={SelectedWorkouts.indexOf(WorkoutId) !== -1} />}
          {!!MultiSelect && <MultiSelectCheckmark Selected={SelectedWorkouts.indexOf(WorkoutId) !== -1} />}
        </EventContainer>
      </Popover>
    );
  }
}

class WeeklyView extends React.Component {
  state = {
    CloseOtherMealPopovers: null,
    CloseOtherWorkoutPopovers: null,
    CurrentDate: moment().format("YYYY-MM-DD"),
    CurrentMonth: moment().month(),
    CurrentWeekNumber: moment().isoWeek(),
    DailyNutritionDate: null,
    ShowNutritionModal: false,
    ShowNutritionPreviewFiltersModal: false,

    Reordering: false,
    ShowReorderTasksAndStatusesModal: false,
    ShowReorderTasksAndStatusesModal_Data: {},
    
    ShowUserTaskAndStatusDetailModal: false,
    ShowUserTaskAndStatusDetailModal_Data: {}
  };

  onChangeUserTaskAndStatusDetailModal_ShowDeleteType = ShowDeleteType => {
    this.setState({ ShowUserTaskAndStatusDetailModal_Data: { ...this.state.ShowUserTaskAndStatusDetailModal_Data, ShowDeleteType } });
  }

  onCloseOtherPopovers = ({ ContentId, PopoverType }) => {
    // PopoverType === 'Meal' || 'Workout'
    this.setState({ [`CloseOther${PopoverType}Popovers`]: ContentId }, () =>
      this.setState({ [`CloseOther${PopoverType}Popovers`]: null })
    );
  };

  onDragEnd_TasksAndStatuses = result => {
    var { source, destination } = result;

    if (!destination) return;
    if (source.index === destination.index) return;

    var { ReorderType } = this.state.ShowReorderTasksAndStatusesModal_Data;

    var NewOrder = [ ...this.state.ShowReorderTasksAndStatusesModal_Data[ReorderType] ];
    var [ removed ] = NewOrder.splice(source.index, 1);
    NewOrder.splice(destination.index, 0, removed);

    var NewShowReorderTasksAndStatusesModal_Data = { ...this.state.ShowReorderTasksAndStatusesModal_Data };
    NewShowReorderTasksAndStatusesModal_Data[ReorderType] = [ ...NewOrder ];
    NewShowReorderTasksAndStatusesModal_Data.Updated = true;

    this.setState({ ShowReorderTasksAndStatusesModal_Data: NewShowReorderTasksAndStatusesModal_Data });
  }

  onMultiSelectMeal = (event, MealId) => {
    event.preventDefault();

    this.props.OnMultiSelectMeals(MealId);
  };

  onMultiSelectWorkout = (event, WorkoutId) => {
    event.preventDefault();

    this.props.OnMultiSelectWorkouts(WorkoutId);
  };

  onReorderTasksStatuses = () => {
    var { KeyId, ReorderType, Updated } = this.state.ShowReorderTasksAndStatusesModal_Data;
    if (Updated) {
      var UpdatedOrder = [];

      UpdatedOrder = this.state.ShowReorderTasksAndStatusesModal_Data[ReorderType].map(TaskStatus => TaskStatus[KeyId]).join(',');

      this.setState({ Reordering: true }, () => {
        if (ReorderType === 'UserStatuses') this.props.Tasks_ReorderStatuses({ UpdatedOrder }).then(() => {
          this.setState({ Reordering: false }, () => {
            this.onToggleShowReorderTasksAndStatusesModal({ ShowReorderTasksAndStatusesModal: false });
            this.props.RefreshWeeklyView();
          });
        })
        else if (ReorderType === 'UserTasks') this.props.Tasks_ReorderTasks({ UpdatedOrder }).then(() => {
          this.setState({ Reordering: false }, () => {
            this.onToggleShowReorderTasksAndStatusesModal({ ShowReorderTasksAndStatusesModal: false });
            this.props.RefreshWeeklyView();
          });
        });
      })
    }
    else this.onToggleShowReorderTasksAndStatusesModal({ ShowReorderTasksAndStatusesModal: false });
  }

  onShowUserTaskAndStatusDetailModal_Delete = () => {
    var { ShowDeleteType: DeleteType, Type, UserStatusId, UserTaskId } = this.state.ShowUserTaskAndStatusDetailModal_Data;

    if (Type === 'UserStatus') {
      this.props.Tasks_RemoveStatus({ DeleteType, UserStatusId }).then(() => {
        this.onToggleShowUserTaskAndStatusDetailModal({ ShowUserTaskAndStatusDetailModal: false });
        this.props.RefreshWeeklyView();
      })
    } else {
      this.props.Tasks_RemoveTask({ DeleteType, UserTaskId }).then(() => {
        this.onToggleShowUserTaskAndStatusDetailModal({ ShowUserTaskAndStatusDetailModal: false });
        this.props.RefreshWeeklyView();
      })
    }
  }

  onToggleShowNutritionPreviewFiltersModal = ({
    ShowNutritionPreviewFiltersModal,
  }) => {
    this.setState({ ShowNutritionPreviewFiltersModal });
  };

  onToggleShowReorderTasksAndStatusesModal = ({ ShowReorderTasksAndStatusesModal, ShowReorderTasksAndStatusesModal_Data = { DayDate: null, KeyId: null, Name: null, ReorderType: null, Updated: false, UserStatuses: [], UserTasks: [] } }) => {
    this.setState({ ShowReorderTasksAndStatusesModal, ShowReorderTasksAndStatusesModal_Data });
  };

  onToggleShowTaskStatusProgress = ({ Id, Type }) => {
    history.push(`/client/${this.props.ClientId}/${Type.toLowerCase()}/${Id}`);
  }

  onToggleShowUserTaskAndStatusDetailModal = ({ ShowUserTaskAndStatusDetailModal, ShowUserTaskAndStatusDetailModal_Data = { ShowDelete: false, ShowDeleteType: null } }) => {
    this.setState({ ShowUserTaskAndStatusDetailModal, ShowUserTaskAndStatusDetailModal_Data });
  };

  onToggleShowUserTaskAndStatusDetailModal_ShowDelete = () => {
    this.setState({ ShowUserTaskAndStatusDetailModal_Data: { ...this.state.ShowUserTaskAndStatusDetailModal_Data, ShowDelete: true } });
  }

  onToggleViewDailyNutrition = (ShowNutritionModal, DayDate) => {
    this.setState({ DailyNutritionDate: DayDate, ShowNutritionModal });
  };

  // TODO: Think about name
  filterSession(session, dayDate) {
    const sessionDate = moment(session.Date).utc().format("YYYY-MM-DD");
    return !session.WorkoutId && sessionDate === dayDate;
  }

  renderAddButton = (IsCurrentMonth, DayDate) => {
    return (
      <SectionContainer
        Hover
        IsCurrentMonth={IsCurrentMonth}
        onClick={() => this.props.OnToggleAddButton(DayDate)}
      >
        <AddButtonImage src={Plus} alt="Add new" />
      </SectionContainer>
    );
  };

  renderDailyCalories = (
    ActivityLevel,
    Calories,
    DayDate,
    DayIndex,
    IsCurrentMonth,
    IsToday
  ) => {
    var { t } = this.props;

    return (
      <SectionContainer
        className="renderDailyCalories"
        Hover
        IsCurrentMonth={IsCurrentMonth}
        IsToday={IsToday}
        onClick={() =>
          this.props.OnToggleActivityLevelModal({
            ActivityLevel,
            Calories,
            DayDate,
            DayIndex,
          })
        }
      >
        <InlineTexts>
          <PageText
            FontFamily="medium"
            FontSize="extra-small"
            NoMargin
            Text={t("MealPlan_Activity_level")}
            TextAlign="center"
          />
          <div>&nbsp;-&nbsp;</div>
          <PageText
            FontFamily="bold"
            FontSize="extra-small"
            NoMargin
            Text={+ActivityLevel ? `${ActivityLevel}` : "/"}
            TextAlign="center"
          />
        </InlineTexts>
        <InlineTexts>
          <PageText
            FontFamily="medium"
            FontSize="extra-small"
            NoMargin
            Text={t("MealPlan_Calories")}
            TextAlign="center"
          />
          <div>&nbsp;-&nbsp;</div>
          <PageText
            FontFamily="bold"
            FontSize="extra-small"
            NoMargin
            Text={`${Calories}`}
            TextAlign="center"
          />
        </InlineTexts>
      </SectionContainer>
    );
  };

  renderDailyMeals = (DailyMeals, IsCurrentMonth) => {
    var DailyMealsLength = DailyMeals.length;

    return DailyMeals.map((DailyMeal, DMIndex) => {
      var { dataLogFoods, DishId, DishName, DishUrl, IsDone, MealDate, uId } = DailyMeal;

      var IsCompleted_Meal = +IsDone ? "complete" : moment(MealDate.substring(0, 10)).format("YYYY-MM-DD") < moment().format("YYYY-MM-DD") ? "incomplete" : "";
      var IsMoreDishes = DMIndex < DailyMealsLength - 1 ? 1 : 0;

      return (
        <React.Fragment key={uId}>
          {!!parseInt(DishId) ? (
            <DailyDish
              CloseOtherMealPopovers={this.state.CloseOtherMealPopovers}
              CloseOtherPopovers={(ContentId) =>
                this.onCloseOtherPopovers({
                  ContentId,
                  PopoverType: "Meal",
                })
              }
              DishName={DishName}
              DishUrl={DishUrl}
              GetMealDishPreview={this.props.GetMealDishPreview}
              IsCompleted_Meal={IsCompleted_Meal}
              IsCurrentMonth={IsCurrentMonth}
              MultiSelect={this.props.MultiSelect}
              onMultiSelectMeal={this.onMultiSelectMeal}
              SelectedMeals={this.props.SelectedMeals}
              TryingGetMealDishPreview={this.props.TryingGetMealDishPreview}
              uId={uId}
              DishType="MealPlan"
            />)
          :
            (
              <DailyDish
                  CloseOtherMealPopovers={this.state.CloseOtherMealPopovers}
                  CloseOtherPopovers={this.onCloseOtherPopovers}
                  dataLogFoodDetail={dataLogFoods}
                  GetMealDishPreview={this.props.GetMealDishPreview}
                  IsCompleted_Meal={IsCompleted_Meal}
                  IsCurrentMonth={IsCurrentMonth}
                  MultiSelect={this.props.MultiSelect}
                  onMultiSelectMeal={this.onMultiSelectMeal}
                  SelectedMeals={this.props.SelectedMeals}
                  TryingGetMealDishPreview={this.props.TryingGetMealDishPreview}
                  uId={uId}
                  DishType="LogFood"
                />
            )
          }

          {!!IsMoreDishes && <Spacer Size="medium" />}
        </React.Fragment>
      );
    })
  }

  renderDailyWorkouts = (DailyWorkouts, IsCurrentMonth) => {
    var { t } = this.props;

    return DailyWorkouts.map(({ AssignedDate, Completed, Duration, IsGarmin, IsGarminMerged, PreviewData, ShowWorkoutTime, SourceId, SurveyData = [], Type, WorkoutId, WorkoutImage, WorkoutLocation, WorkoutName, VideoSessionInfo, }, index) => {
        var IsMoreWorkouts = index < DailyWorkouts.length - 1 ? 1 : 0;

        return (
          <React.Fragment key={WorkoutId}>
            <DailyWorkout
              AssignedDate={AssignedDate}
              CloseOtherWorkoutPopovers={this.state.CloseOtherWorkoutPopovers}
              CloseOtherPopovers={(ContentId) =>
                this.onCloseOtherPopovers({ ContentId, PopoverType: "Workout" })
              }
              Completed={Completed}
              Duration={Duration}
              GetWorkoutPreview={this.props.GetWorkoutPreview}
              IsCurrentMonth={IsCurrentMonth}
              MultiSelect={this.props.MultiSelect}
              onMultiSelectWorkout={this.onMultiSelectWorkout}
              PreviewData={PreviewData}
              SelectedWorkouts={this.props.SelectedWorkouts}
              ShowGarmin={!!IsGarmin || !!IsGarminMerged}
              ShowWorkoutImage={this.props.ShowWorkoutImage}
              ShowWorkoutPreview={this.props.ShowWorkoutPreview}
              ShowWorkoutTime={ShowWorkoutTime}
              SourceId={SourceId}
              SurveyData={SurveyData}
              TryingGetWorkouthPreview={this.props.TryingGetWorkouthPreview}
              WorkoutId={WorkoutId}
              WorkoutImage={WorkoutImage}
              WorkoutLocation={WorkoutLocation}
              WorkoutName={WorkoutName}
              WorkoutType={Type}
              VideoSessionInfo={VideoSessionInfo}
              t={t}
            />

            {!!IsMoreWorkouts && <Spacer Size="medium" />}
          </React.Fragment>
        );
      }
    );
  };

  renderDailyGroupWorkouts = (DailyWorkouts, IsCurrentMonth) => {
    var { t } = this.props;

    return DailyWorkouts.map(
      (
        {
          AssignedDate,
          Completed,
          CreatedUserName,
          Duration,
          GroupWorkoutId,
          NumMembers,
          ShowWorkoutTime,
          WorkoutImage,
          WorkoutLocation,
          WorkoutName,
        },
        index
      ) => {
        var IsCompleted_Workout =
          +Completed === 1
            ? "complete"
            : +Completed > 0
            ? "partial"
            : moment(AssignedDate.substring(0, 10)).format("YYYY-MM-DD") <
              moment().format("YYYY-MM-DD")
            ? "incomplete"
            : "";
        var IsMoreWorkouts = index < DailyWorkouts.length - 1 ? 1 : 0;

        var OverlayText = "";
        if (ShowWorkoutTime) OverlayText = AssignedDate.substring(11, 16);
        if (ShowWorkoutTime && WorkoutLocation) OverlayText += ` - `;
        if (WorkoutLocation) OverlayText += `${WorkoutLocation}`;

        return (
          <React.Fragment key={GroupWorkoutId}>
            <EventContainer
              IsCurrentMonth={IsCurrentMonth}
              Toggleable={!!this.props.MultiSelect}
              onClick={
                this.props.MultiSelect
                  ? (event) => this.onMultiSelectWorkout(event, GroupWorkoutId)
                  : null
              }
            >
              <EventImageContainer
                completed={IsCompleted_Workout}
                to={`/group/workout/${GroupWorkoutId}`}
              >
                <Image
                  Alt="Workout"
                  ImageSrc={WorkoutImage || NoImage}
                  LoadingImageSrc={NoImage}
                  SelfLoading
                  ShowOverlay
                />
                {OverlayText && (
                  <OverlayTextContainer>{OverlayText}</OverlayTextContainer>
                )}
              </EventImageContainer>

              <Spacer Size="extra-extra-small" />

              <EventNameContainer
                completed={IsCompleted_Workout}
                to={`/group/workout/${GroupWorkoutId}`}
              >
                <PageText
                  FontFamily="medium"
                  FontSize="medium-1"
                  JustifyContent="center"
                  NoMargin
                  Text={WorkoutName}
                  TextAlign="center"
                />

                <Spacer Size="extra-extra-small" />

                <PageText
                  FontFamily="semibold"
                  FontSize="medium-1"
                  JustifyContent="center"
                  NoMargin
                  Text={`${NumMembers} ${
                    +NumMembers === 1
                      ? t("member").toLowerCase()
                      : t("members").toLowerCase()
                  }`}
                  TextAlign="center"
                />

                <Spacer Size="extra-extra-small" />

                <PageText
                  FontFamily="medium"
                  FontSize="medium-1"
                  JustifyContent="center"
                  NoMargin
                  Text={`@${CreatedUserName}`}
                  TextAlign="center"
                />

                <Spacer Size="extra-extra-small" />

                {this.renderDuration(Duration)}
              </EventNameContainer>

              {!!this.props.MultiSelect && (
                <ToggleShadow
                  Selected={
                    this.props.SelectedWorkouts.indexOf(GroupWorkoutId) !== -1
                  }
                />
              )}
              {!!this.props.MultiSelect && (
                <MultiSelectCheckmark
                  Selected={
                    this.props.SelectedWorkouts.indexOf(GroupWorkoutId) !== -1
                  }
                />
              )}
            </EventContainer>

            {IsMoreWorkouts ? <Spacer Size="medium" /> : null}
          </React.Fragment>
        );
      }
    );
  };

  renderDailyTask = UserTask => {
    var { t } = this.props;
    var { Amount, AssignedDate, Completed, GoalAmount, Icon, ReadOnly, ResponseHidden, TaskName, TaskType, UserTaskId } = UserTask;

    if (!!+ResponseHidden) Completed = 0;

    var CompletionStatus = '';
    if (Completed) CompletionStatus = 'complete';
    else if (moment(AssignedDate.substring(0, 10)).format("YYYY-MM-DD") < moment().format("YYYY-MM-DD")) CompletionStatus = 'incomplete';

    return (
      <styles.TasksAndStatusContainer key={UserTaskId} className="TasksAndStatusContainer" ReadOnly={ReadOnly} CompletionStatus={CompletionStatus} onClick={(!!ReadOnly || !!ResponseHidden) ? () => null : () => this.onToggleShowUserTaskAndStatusDetailModal({ ShowUserTaskAndStatusDetailModal: true, ShowUserTaskAndStatusDetailModal_Data: { Type: 'UserTask', ...UserTask } })}>
        <styles.TasksAndStatusContainerTop className="TasksAndStatusContainerTop">
          <styles.TasksAndStatusContainerLeft className="TasksAndStatusContainerLeft"><IconComponent src={IconsList[Icon]} />
            <PageText FontFamily="medium" FontSize="small" JustifyContent="flex-start" NoMargin Text={`${TaskName}`} TextAlign="left" />
          </styles.TasksAndStatusContainerLeft>
          
          {(TaskType === 2 || TaskType === 3) && (!!Amount || !!Completed) && <PageText FontFamily="semibold" FontSize="small" JustifyContent="flex-end" NoMargin Text={`${Amount}`} TextAlign="right" WordBreak="keep-all" />}
        </styles.TasksAndStatusContainerTop>

        {(TaskType === 2 || TaskType === 3) && <PageText FontFamily="medium-italic" FontSize="small" JustifyContent="flex-start" NoMargin Text={`${TaskType === 2 ? 'min' : 'max'} amount: ${GoalAmount}`} TextAlign="left" WordBreak="keep-all" />}

        {
          !!ResponseHidden &&
          <>
            <Spacer Size="extra-extra-small" />

            <PageText FontFamily="medium-italic" FontSize="small" JustifyContent="center" NoMargin Text={t('task_status_response_is_hidden')} TextAlign="center" />
          </>
         }
      </styles.TasksAndStatusContainer>
    );
  }

  renderDailyStatus = UserStatus => {
    var { t } = this.props;
    var { AssignedDate, Completed, Icon, Rating, ReadOnly, ResponseHidden, StatusName, UserStatusId } = UserStatus;

    var CompletionStatus = '';
    if (Completed) CompletionStatus = 'complete';
    else if (moment(AssignedDate.substring(0, 10)).format("YYYY-MM-DD") < moment().format("YYYY-MM-DD")) CompletionStatus = 'incomplete';

    return (
      <styles.TasksAndStatusContainer key={UserStatusId} className="TasksAndStatusContainer" ReadOnly={ReadOnly} CompletionStatus={CompletionStatus} onClick={(!!ReadOnly || !!ResponseHidden) ? () => null : () => this.onToggleShowUserTaskAndStatusDetailModal({ ShowUserTaskAndStatusDetailModal: true, ShowUserTaskAndStatusDetailModal_Data: { Type: 'UserStatus', ...UserStatus }})}>
         <styles.TasksAndStatusContainerTop className="TasksAndStatusContainerTop">
          <styles.TasksAndStatusContainerLeft className="TasksAndStatusContainerLeft"><IconComponent src={IconsList[Icon]} />
            <PageText FontFamily="medium" FontSize="small" JustifyContent="flex-start" NoMargin Text={`${StatusName}`} TextAlign="left" />
          </styles.TasksAndStatusContainerLeft>
          
          {Rating && <PageText FontFamily="semibold" FontSize="small" JustifyContent="flex-end" NoMargin Text={`${Rating}`} TextAlign="right" WordBreak="keep-all" />}
         </styles.TasksAndStatusContainerTop>

         <PageText FontFamily="medium-italic" FontSize="small" JustifyContent="flex-start" NoMargin Text="1 - 10" TextAlign="left" WordBreak="keep-all" />

         {
          !!Completed && !!ResponseHidden &&
          <>
            <Spacer Size="extra-extra-small" />

            <PageText FontFamily="medium-italic" FontSize="small" JustifyContent="center" NoMargin Text={t('task_status_response_is_hidden')} TextAlign="center" />
          </>
         }
      </styles.TasksAndStatusContainer>
    );
  }

  renderDailyTasksAndStatuses = ({ DayDate, IsCurrentMonth, UserStatuses, UserTasks }) => {
    var { t } = this.props;

    return (
      <styles.TasksAndStatusesContainer className="TasksAndStatusesContainer">
        {
          UserTasks.length > 0 &&
          <styles.TasksAndStatusesSectionHeaderContainer className="TasksAndStatusesSectionHeaderContainer">
            <PageText FontFamily="semibold" FontSize="small" JustifyContent="center" NoMargin Text={t('tasks')} TextAlign="center" />

            {UserTasks.length > 1 && <ReorderIcon src={Reorder} alt="Reorder" onClick={() => this.onToggleShowReorderTasksAndStatusesModal({ ShowReorderTasksAndStatusesModal: true, ShowReorderTasksAndStatusesModal_Data: { DayDate, KeyId: 'UserTaskId', ReorderType: 'UserTasks', UserTasks: [ ...UserTasks ] } })} />}
          </styles.TasksAndStatusesSectionHeaderContainer>
        }
        {UserTasks.map(UserTask => this.renderDailyTask(UserTask))}

        {
          UserStatuses.length > 0 &&
          <>
            {UserTasks.length > 0 && <Spacer Size="small" />}

            <styles.TasksAndStatusesSectionHeaderContainer className="TasksAndStatusesSectionHeaderContainer">
              <PageText FontFamily="semibold" FontSize="small" JustifyContent="center" NoMargin Text={t('statuses')} TextAlign="center" />
              
              {UserStatuses.length > 1 && <ReorderIcon src={Reorder} alt="Reorder" onClick={() => this.onToggleShowReorderTasksAndStatusesModal({ ShowReorderTasksAndStatusesModal: true, ShowReorderTasksAndStatusesModal_Data: { DayDate, KeyId: 'UserStatusId', ReorderType: 'UserStatuses', UserStatuses: [ ...UserStatuses ] } })} />}
            </styles.TasksAndStatusesSectionHeaderContainer>
          </>
        }
        {UserStatuses.map(UserStatus => this.renderDailyStatus(UserStatus))}
      </styles.TasksAndStatusesContainer>
    )
  }

  renderDayHeader = (DayDate, DayName, IsCurrentMonth, IsToday) => {
    return (
      <DayHeaderContainer IsCurrentMonth={IsCurrentMonth} IsToday={IsToday}>
        <PageText
          FontFamily="bold"
          FontSize="medium-2"
          JustifyContent="flex-start"
          NoMargin
          Text={moment(DayDate).format("[WeeklyViewDayHeader]")}
          TextAlign="left"
        />
        <PageText
          FontFamily="medium"
          FontSize="medium-1"
          JustifyContent="flex-end"
          NoMargin
          Text={moment(DayDate).format("ddd")}
          TextAlign="right"
        />
      </DayHeaderContainer>
    );
  };

  renderDuration = (Duration) => {
    var { t } = this.props;

    return (
      <DurationContainer>
        <ClockIcon src={Clock} alt="Duration" />

        <PageText
          ElementType="span"
          FontFamily="semibold"
          FontSize="medium-1"
          JustifyContent="center"
          NoMargin
          Text={`${Duration} ${t("min_str")}`}
          TextAlign="center"
        />
      </DurationContainer>
    );
  };

  renderNutritionBox = ({
    Calories,
    DayDate,
    IsCurrentMonth,
    NutritionPreview: NutritionData,
  }) => {
    return (
      <NutritionPreview
        Calories={Calories}
        IsCurrentMonth={IsCurrentMonth}
        NutritionData={NutritionData}
        OnToggleShowNutritionFactsModal={(ShowNutritionModal) =>
          this.onToggleViewDailyNutrition(ShowNutritionModal, DayDate)
        }
        OnToggleShowNutritionPreviewFiltersModal={(
          ShowNutritionPreviewFiltersModal
        ) =>
          this.onToggleShowNutritionPreviewFiltersModal({
            ShowNutritionPreviewFiltersModal,
          })
        }
      />
    );
  };

  renderViewDailyNutrition = (DayDate, IsCurrentMonth) => {
    var { t } = this.props;

    return (
      <SectionContainer
        Hover
        IsCurrentMonth={IsCurrentMonth}
        onClick={() => this.onToggleViewDailyNutrition(true, DayDate)}
      >
        <PageText
          FontFamily="medium"
          FontSize="small"
          NoMargin
          Text={t("MealPlan_Nutrition_for_today")}
          TextAlign="center"
        />
      </SectionContainer>
    );
  };

  renderNutritionModal = () => {
    var { ShowNutritionModal } = this.state;

    if (ShowNutritionModal) {
      var { ClientId } = this.props;
      var { DailyNutritionDate } = this.state;

      return (
        <NutritionFacts
          ClientId={ClientId}
          DayDate={DailyNutritionDate}
          OnHide={() => this.onToggleViewDailyNutrition(false)}
          Show={ShowNutritionModal}
        />
      );
    }
  };

  renderNutritionPreviewFiltersModal = () => {
    var { ShowNutritionPreviewFiltersModal } = this.state;

    if (ShowNutritionPreviewFiltersModal) {
      return (
        <NutritionPreviewFilters
          OnHide={() =>
            this.onToggleShowNutritionPreviewFiltersModal({
              ShowNutritionPreviewFiltersModal: false,
            })
          }
          Show={ShowNutritionPreviewFiltersModal}
        />
      );
    }
  };

  renderReorderTasksAndStatusesModal = () => {
    var { ShowReorderTasksAndStatusesModal } = this.state;

    if (ShowReorderTasksAndStatusesModal) {
      var { t } = this.props;

      return (
        <Info
          BottomButton="submit"
          BottomButtonOnClick={this.onReorderTasksStatuses}
          BottomButtonText={t('_save')}
          InformationRenderer={this.renderReorderTasksAndStatusesModal_Information}
          Loading={this.state.Reordering}
          OnHide={() => this.onToggleShowReorderTasksAndStatusesModal({ ShowReorderTasksAndStatusesModal: false })}
          Show={ShowReorderTasksAndStatusesModal}
          Size="small"
        />
      );
    }
  }

  renderReorderTasksAndStatusesModal_Information = () => {
    var { t } = this.props;
    var { DayDate, KeyId, ReorderType } = this.state.ShowReorderTasksAndStatusesModal_Data;

    var Title = t('_reorder');
    if (ReorderType === 'UserStatuses') Title += ` ${t('statuses')}`;
    else if (ReorderType === 'UserTasks') Title += ` ${t('tasks')}`;

    // isDragging={snapshot.isDragging}

    return (
      <>
        <PageText FontFamily="semibold" FontSize="medium-2" JustifyContent="center" NoMargin Text={Title} TextAlign="center" />
        <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={moment(DayDate).format("[WeeklyViewHeader]")} TextAlign="center" />

        <Spacer Size="small" />

        <DragDropContext
          onDragEnd={this.onDragEnd_TasksAndStatuses}
        >
          <Droppable droppableId={ReorderType}>
            {(provided, snapshot) => (
               <TaskStatusDraggableContainer className="TaskStatusDraggableContainer"
               ref={provided.innerRef}
               {...provided.droppableProps}
             >
              {
                this.state.ShowReorderTasksAndStatusesModal_Data[ReorderType].map((TaskStatus, index) => {
                  return (
                    <Draggable key={TaskStatus[KeyId]} draggableId={`${TaskStatus[KeyId]}`} index={index}>
                      {(provided) => (
                        <div
                          className="task-status"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          {ReorderType === 'UserStatuses' && this.renderDailyStatus({ ...TaskStatus, ReadOnly: true })}
                          {ReorderType === 'UserTasks' && this.renderDailyTask({ ...TaskStatus, ReadOnly: true })}
                        </div>
                      )}
                    </Draggable>
                  );
                })
              }

              {
                snapshot.isDraggingOver && <Spacer Size="extra-large" />
              }
             </TaskStatusDraggableContainer>
            )}
          </Droppable>
        </DragDropContext>
      </>
    );
  }

  renderTasksAndStatuses = WeeklyTasksStatuses => {
    var { t } = this.props;

    return WeeklyTasksStatuses.map(({ WeekNumber, UserTasksAndStatuses }) => {
      var IsCurrentWeekNumber = WeekNumber === this.state.CurrentWeekNumber;

      return (
        <WeekContainer className="WeekContainer" key={WeekNumber}>
          <WeekNumberBar className="WeekNumberBar" IsCurrentWeekNumber={IsCurrentWeekNumber}>
            <PageText FontColor={IsCurrentWeekNumber ? "white" : "black"} FontFamily="bold" FontSize="medium-2" JustifyContent="flex-start" NoMargin Text={`${t("timeperiod_week_singular_first_letter_capital")} ${WeekNumber}`} TextAlign="left" />
          </WeekNumberBar>

          <Spacer Size="extra-small" />

          <WeeklyEventsContainer className="WeeklyEventsContainer">
            {UserTasksAndStatuses.map(({ DayDate, DayName, UserStatuses, UserTasks }) => {
                var IsCurrentMonth = moment(DayDate).month() === this.state.CurrentMonth;
                var IsToday = moment(DayDate).format("YYYY-MM-DD") === this.state.CurrentDate;

                return (
                  <WeeklyDay key={DayDate} IsCurrentMonth={IsCurrentMonth}>
                    {this.renderDayHeader(DayDate, DayName, IsCurrentMonth, IsToday)}

                    <Spacer Size="extra-small" />

                    {this.renderDailyTasksAndStatuses({ DayDate, IsCurrentMonth, UserStatuses, UserTasks })}
                  </WeeklyDay>
                );
              }
            )}
          </WeeklyEventsContainer>
        </WeekContainer>
      );
    })
  }

  renderUserTaskAndStatusDetailModal = () => {
    var { ShowUserTaskAndStatusDetailModal } = this.state;

    if (ShowUserTaskAndStatusDetailModal) {
      return (
        <Info
          InformationRenderer={this.renderUserTaskAndStatusDetailModal_Information}
          OnHide={() => this.onToggleShowUserTaskAndStatusDetailModal({ ShowUserTaskAndStatusDetailModal: false })}
          Show={ShowUserTaskAndStatusDetailModal}
          Size="small"
        />
      );
    }
  }

  renderUserTaskAndStatusDetailModal_Information = () => {
    var { t } = this.props;
    var { AssignedDate, Description, ShowDelete, ShowDeleteType, Type, StatusId, TaskId } = this.state.ShowUserTaskAndStatusDetailModal_Data;

    var Buttons = [];
    Buttons.push({ BackgroundColor: 'red-bittersweet', BackgroundColorHover: 'red-bittersweet-hover', Border: 'none', Color: 'white', ColorHover: 'white', Disabled: !ShowDeleteType, FontFamily: 'semibold', OnClick: this.onShowUserTaskAndStatusDetailModal_Delete, Title: t('delete_with_first_char_uppercase') });

    var Id = StatusId;
    if (Type === 'UserTask') Id = TaskId;

    return (
      <>
        <styles.UserTaskAndStatusDetailModal_StatusContainer className="UserTaskAndStatusDetailModal_StatusContainer">
          <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="center" NoMargin Text={moment(AssignedDate).format('[NumbersDate]')} TextAlign="center" />

          <Spacer Size="small" />
          
          {
            Type === 'UserStatus' ?
            this.renderDailyStatus({ ...this.state.ShowUserTaskAndStatusDetailModal_Data, ReadOnly: true })
          :
            this.renderDailyTask({ ...this.state.ShowUserTaskAndStatusDetailModal_Data, ReadOnly: true })
          }
        </styles.UserTaskAndStatusDetailModal_StatusContainer>

        <Spacer Size="small" />

        <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={Description || ''} TextAlign="center" />

        <Spacer Size="medium" />

        <PageText FontFamily="semibold-italic" FontSize="medium-2" JustifyContent="center" NoMargin OnClick={() => this.onToggleShowTaskStatusProgress({ Id, Type })} Text={t('show_progress')} TextAlign="center" />

        <Spacer Size="medium" />

        <PageText FontFamily="medium-italic" FontSize="medium-1" JustifyContent="center" NoMargin OnClick={() => this.onToggleShowUserTaskAndStatusDetailModal_ShowDelete()} Text={`${t('delete_with_first_char_uppercase')}?`} TextAlign="center" />

        {
          !!ShowDelete &&
          <>
            <Spacer Size="extra-extra-small" />

            <styles.RadioButtonParentContainer className="RadioButtonParentContainer">
              <styles.PseudoCheckbox className="PseudoCheckbox" BackgroundColor="274775" onClick={() => this.onChangeUserTaskAndStatusDetailModal_ShowDeleteType('Single')} Selected={ShowDeleteType === 'Single'} />

              <styles.RadioButtonPageText ElementType="span" FontFamily={ShowDeleteType === 'Single' ? 'semibold' : 'medium'} FontSize="medium-1" JustifyContent="flex-start" OnClick={() => this.onChangeUserTaskAndStatusDetailModal_ShowDeleteType('Single')} Text={t('taskstatus_delete_single')} TextAlign="left" />
            </styles.RadioButtonParentContainer>

            <Spacer Size="extra-extra-small" />

            <styles.RadioButtonParentContainer className="RadioButtonParentContainer">
              <styles.PseudoCheckbox className="PseudoCheckbox" BackgroundColor="274775" onClick={() => this.onChangeUserTaskAndStatusDetailModal_ShowDeleteType('Future')} Selected={ShowDeleteType === 'Future'} />

              <styles.RadioButtonPageText ElementType="span" FontFamily={ShowDeleteType === 'Future' ? 'semibold' : 'medium'} FontSize="medium-1" JustifyContent="flex-start" OnClick={() => this.onChangeUserTaskAndStatusDetailModal_ShowDeleteType('Future')} Text={t('taskstatus_delete_future')} TextAlign="left" />
            </styles.RadioButtonParentContainer>

            <Spacer Size="extra-extra-small" />

            <styles.RadioButtonParentContainer className="RadioButtonParentContainer">
              <styles.PseudoCheckbox className="PseudoCheckbox" BackgroundColor="274775" onClick={() => this.onChangeUserTaskAndStatusDetailModal_ShowDeleteType('AllClients')} Selected={ShowDeleteType === 'AllClients'} />

              <styles.RadioButtonPageText ElementType="span" FontFamily={ShowDeleteType === 'AllClients' ? 'semibold' : 'medium'} FontSize="medium-1" JustifyContent="flex-start" OnClick={() => this.onChangeUserTaskAndStatusDetailModal_ShowDeleteType('AllClients')} Text={t('taskstatus_delete_allclients')} TextAlign="left" />
            </styles.RadioButtonParentContainer>

            <Spacer Size="small" />

            <ButtonGroup
              Buttons={Buttons}
              ButtonWidth="fit-content"
              NotTouching
              OwnRows={false}
            />
          </>
        }
      </>
    );
  }

  getSessionTab(dayDate) {
    const dateDifferenceInDays = getDateDifference(
      dayDate,
      this.state.CurrentDate,
      "days"
    );
    return (
      dateDifferenceInDays === 0
        ? Today
        : dateDifferenceInDays < 0
        ? Upcoming
        : Completed
    ).toLowerCase();
  }

  render() {
    var { t } = this.props;
    var {
      Meals,
      TryingCopyGroupWorkouts,
      TryingCopyMeals,
      TryingCopyWorkouts,
      TryingDeleteGroupWorkouts,
      TryingDeleteMeals,
      TryingDeleteWorkouts,
      TryingMoveGroupWorkouts,
      TryingMoveMeals,
      TryingMoveWorkouts,
      Type,
      UserTasksAndStatuses,
      Workouts
    } = this.props;

    if (Type === "Meals") {
      var WeeklyMeals = [];
      var WeeklyMealsObj = { WeekNumber: null, Meals: [] };
      for (var a = 0; a < Meals.length; a++) {
        if (a % 7 === 0)
          WeeklyMealsObj = {
            WeekNumber: moment(Meals[a].DayDate).isoWeek(),
            Meals: [],
          };

        WeeklyMealsObj.Meals.push(Meals[a]);

        if ((a + 1) % 7 === 0) WeeklyMeals.push(WeeklyMealsObj);
      }
    } else if (Type === "GroupWorkouts" || Type === "Workouts") {
      var WeeklyWorkouts = [];
      var WeeklyWorkoutsObj = { WeekNumber: null, Workouts: [] };
      for (var b = 0; b < Workouts.length; b++) {
        if (b % 7 === 0)
          WeeklyWorkoutsObj = {
            WeekNumber: moment(Workouts[b].DayDate).isoWeek(),
            Workouts: [],
          };

        WeeklyWorkoutsObj.Workouts.push(Workouts[b]);

        if ((b + 1) % 7 === 0) WeeklyWorkouts.push(WeeklyWorkoutsObj);
      }
    } else if (Type === "Tasks") {
      var WeeklyTasksStatuses = [];
      var WeeklyTasksStatusesObj = { WeekNumber: null, UserTasksAndStatuses: [] };
      for (a = 0; a < UserTasksAndStatuses.length; a++) {
        if (a % 7 === 0) WeeklyTasksStatusesObj = { WeekNumber: moment(UserTasksAndStatuses[a].DayDate).isoWeek(), UserTasksAndStatuses: [] };

        WeeklyTasksStatusesObj.UserTasksAndStatuses.push(UserTasksAndStatuses[a]);

        if ((a + 1) % 7 === 0) WeeklyTasksStatuses.push(WeeklyTasksStatusesObj);
      }
    }

    return (
      <>
        <WeeklyViewContainer className="WeeklyViewContainer">
          {(this.props.Loading ||
            TryingCopyGroupWorkouts ||
            TryingCopyMeals ||
            TryingCopyWorkouts ||
            TryingDeleteGroupWorkouts ||
            TryingDeleteMeals ||
            TryingDeleteWorkouts ||
            TryingMoveGroupWorkouts ||
            TryingMoveMeals ||
            TryingMoveWorkouts) && <Loading />}

          {Type === "Meals"
            ? WeeklyMeals &&
              WeeklyMeals.map(({ WeekNumber, Meals }) => {
                var IsCurrentWeekNumber =
                  WeekNumber === this.state.CurrentWeekNumber;

                return (
                  <WeekContainer className="WeekContainer" key={WeekNumber}>
                    <WeekNumberBar
                      className="WeekNumberBar"
                      IsCurrentWeekNumber={IsCurrentWeekNumber}
                    >
                      <PageText
                        FontColor={IsCurrentWeekNumber ? "white" : "black"}
                        FontFamily="bold"
                        FontSize="medium-2"
                        JustifyContent="flex-start"
                        NoMargin
                        Text={`${t(
                          "timeperiod_week_singular_first_letter_capital"
                        )} ${WeekNumber}`}
                        TextAlign="left"
                      />
                    </WeekNumberBar>

                    <Spacer Size="extra-small" />

                    <WeeklyEventsContainer className="WeeklyEventsContainer">
                      {Meals.map(
                        (
                          {
                            ActivityLevel,
                            Calories,
                            DayDate,
                            DayName,
                            dataMeals,
                            NutritionPreview,
                            OtherVideoSessions,
                          },
                          DayIndex
                        ) => {
                          var IsCurrentMonth =
                            moment(DayDate).month() === this.state.CurrentMonth;
                          var IsToday =
                            moment(DayDate).format("YYYY-MM-DD") ===
                            this.state.CurrentDate;

                          return (
                            <WeeklyDay
                              key={DayDate}
                              IsCurrentMonth={IsCurrentMonth}
                            >
                              {this.renderDayHeader(
                                DayDate,
                                DayName,
                                IsCurrentMonth,
                                IsToday
                              )}

                              <Spacer Size="extra-small" />

                              {this.renderDailyCalories(
                                ActivityLevel,
                                Calories,
                                DayDate,
                                DayIndex,
                                IsCurrentMonth,
                                IsToday
                              )}

                              {!!(dataMeals && dataMeals.length > 0) && (
                                <>
                                  <Spacer Size="extra-small" />

                                  {this.renderNutritionBox({
                                    Calories,
                                    DayDate,
                                    IsCurrentMonth,
                                    NutritionPreview,
                                  })}
                                </>
                              )}

                              <Spacer Size="extra-small" />

                              {this.renderAddButton(IsCurrentMonth, DayDate)}

                              <Spacer Size="medium" />

                              {OtherVideoSessions &&
                                OtherVideoSessions.map((session, index) => (
                                  <React.Fragment key={`OtherVideoSession-${index}`}>
                                    <Link
                                      key={session.VideoSessionId}
                                      to={`/video-calls?tab=${this.getSessionTab(
                                        DayDate
                                      )}`}
                                    >
                                      <VideoCall
                                        key={session.VideoSessionId}
                                        SingleSession={session}
                                      />
                                    </Link>
                                    {index <= OtherVideoSessions.length - 1 && (
                                      <Spacer Size="medium" />
                                    )}
                                  </React.Fragment>
                                ))}

                              {dataMeals && dataMeals.length > 0 && this.renderDailyMeals(dataMeals, IsCurrentMonth)}
                            </WeeklyDay>
                          );
                        }
                      )}
                    </WeeklyEventsContainer>
                  </WeekContainer>
                );
              })
            : Type === "Workouts"
            ? WeeklyWorkouts &&
              WeeklyWorkouts.map(({ WeekNumber, Workouts }) => {
                var IsCurrentWeekNumber = WeekNumber === this.state.CurrentWeekNumber;

                return (
                  <WeekContainer className="WeekContainer" key={WeekNumber}>
                    <WeekNumberBar className="WeekNumberBar" IsCurrentWeekNumber={IsCurrentWeekNumber}>
                      <PageText FontColor={IsCurrentWeekNumber ? "white" : "black"} FontFamily="bold" FontSize="medium-2" JustifyContent="flex-start" NoMargin Text={`${t("timeperiod_week_singular_first_letter_capital")} ${WeekNumber}`} TextAlign="left" />
                    </WeekNumberBar>

                    <Spacer Size="extra-small" />

                    <WeeklyEventsContainer className="WeeklyEventsContainer">
                      {Workouts.map(({ DayDate, DayName, Workouts, OtherVideoSessions }) => {
                          var IsCurrentMonth = moment(DayDate).month() === this.state.CurrentMonth;
                          var IsToday = moment(DayDate).format("YYYY-MM-DD") === this.state.CurrentDate;

                          return (
                            <WeeklyDay key={DayDate} IsCurrentMonth={IsCurrentMonth}>
                              {this.renderDayHeader(DayDate, DayName, IsCurrentMonth, IsToday)}

                              <Spacer Size="extra-small" />

                              {this.renderAddButton(IsCurrentMonth, DayDate)}

                              <Spacer Size="medium" />

                              {OtherVideoSessions.map((session, index) => (
                                <React.Fragment key={`OtherVideoSession-${index}`}>
                                  <Link key={session.VideoSessionId} to={`/video-calls?tab=${this.getSessionTab(DayDate)}`}>
                                    <VideoCall key={session.VideoSessionId} SingleSession={session} />
                                  </Link>

                                  {index <= OtherVideoSessions.length - 1 && (
                                    <Spacer Size="medium" />
                                  )}
                                </React.Fragment>
                              ))}

                              {this.renderDailyWorkouts(Workouts, IsCurrentMonth)}
                            </WeeklyDay>
                          );
                        }
                      )}
                    </WeeklyEventsContainer>
                  </WeekContainer>
                );
              })
            : Type === "GroupWorkouts"
            ? WeeklyWorkouts &&
              WeeklyWorkouts.map(({ WeekNumber, Workouts }) => {
                var IsCurrentWeekNumber =
                  WeekNumber === this.state.CurrentWeekNumber;

                return (
                  <WeekContainer className="WeekContainer" key={WeekNumber}>
                    <WeekNumberBar
                      className="WeekNumberBar"
                      IsCurrentWeekNumber={IsCurrentWeekNumber}
                    >
                      <PageText
                        FontColor={IsCurrentWeekNumber ? "white" : "black"}
                        FontFamily="bold"
                        FontSize="medium-2"
                        JustifyContent="flex-start"
                        NoMargin
                        Text={`${t(
                          "timeperiod_week_singular_first_letter_capital"
                        )} ${WeekNumber}`}
                        TextAlign="left"
                      />
                    </WeekNumberBar>

                    <Spacer Size="extra-small" />

                    <WeeklyEventsContainer className="WeeklyEventsContainer">
                      {Workouts.map(({ DayDate, DayName, Workouts }) => {
                        var IsCurrentMonth =
                          moment(DayDate).month() === this.state.CurrentMonth;
                        var IsToday =
                          moment(DayDate).format("YYYY-MM-DD") ===
                          this.state.CurrentDate;

                        return (
                          <WeeklyDay
                            key={DayDate}
                            IsCurrentMonth={IsCurrentMonth}
                          >
                            {this.renderDayHeader(
                              DayDate,
                              DayName,
                              IsCurrentMonth,
                              IsToday
                            )}

                            <Spacer Size="extra-small" />

                            {this.renderAddButton(IsCurrentMonth, DayDate)}

                            <Spacer Size="medium" />

                            {this.renderDailyGroupWorkouts(
                              Workouts,
                              IsCurrentMonth
                            )}
                          </WeeklyDay>
                        );
                      })}
                    </WeeklyEventsContainer>
                  </WeekContainer>
                );
              })
            : Type === "GroupWorkouts1"
            ? Workouts &&
              Workouts.map(({ DayDate, DayName, Workouts }) => {
                var IsCurrentMonth =
                  moment(DayDate).month() === this.state.CurrentMonth;
                var IsToday =
                  moment(DayDate).format("YYYY-MM-DD") ===
                  this.state.CurrentDate;

                return (
                  <div key={DayDate}>
                    {this.renderDayHeader(
                      DayDate,
                      DayName,
                      IsCurrentMonth,
                      IsToday
                    )}

                    <Spacer Size="extra-small" />

                    {this.renderAddButton(IsCurrentMonth, DayDate)}

                    <Spacer Size="medium" />

                    {this.renderDailyGroupWorkouts(Workouts, IsCurrentMonth)}
                  </div>
                );
              })
            : Type === 'Tasks'
            ? this.renderTasksAndStatuses(WeeklyTasksStatuses)
            : null}
        </WeeklyViewContainer>

        {this.renderNutritionModal()}
        {this.renderNutritionPreviewFiltersModal()}
        {this.renderReorderTasksAndStatusesModal()}
        {this.renderUserTaskAndStatusDetailModal()}
      </>
    );
  }
}

WeeklyView.propTypes = {
  ClientId: PropTypes.number,
  Loading: PropTypes.bool,
  Meals: PropTypes.array,
  MultiSelect: PropTypes.bool,
  OnMultiSelectMeals: PropTypes.func,
  OnMultiSelectWorkouts: PropTypes.func,
  SelectedMeals: PropTypes.array,
  SelectedWorkouts: PropTypes.array,
  ShowWorkoutImage: PropTypes.bool,
  ShowWorkoutPreview: PropTypes.bool,
  OnToggleActivityLevelModal: PropTypes.func,
  OnToggleAddButton: PropTypes.func,
  Type: PropTypes.string.isRequired,
  Workouts: PropTypes.array,
};

const mapStateToProps = (state) => {
  return {
    TryingCopyGroupWorkouts: state.Group.TryingCopyGroupWorkouts,
    TryingDeleteGroupWorkouts: state.Group.TryingDeleteGroupWorkouts,
    TryingMoveGroupWorkouts: state.Group.TryingMoveGroupWorkouts,

    TryingCopyMeals: state.Meals.TryingCopyMeals,
    TryingDeleteMeals: state.Meals.TryingDeleteMeals,
    TryingGetMealDishPreview: state.Meals.TryingGetMealDishPreview,
    TryingMoveMeals: state.Meals.TryingMoveMeals,

    TryingCopyWorkouts: state.Workouts.TryingCopyWorkouts,
    TryingDeleteWorkouts: state.Workouts.TryingDeleteWorkouts,
    TryingGetWorkoutPreview: state.Workouts.TryingGetWorkoutPreview,
    TryingMoveWorkouts: state.Workouts.TryingMoveWorkouts,
  };
};

export default withTranslation()(connect(mapStateToProps, { GetMealDishPreview, GetWorkoutPreview, Tasks_RemoveStatus, Tasks_RemoveTask, Tasks_ReorderStatuses, Tasks_ReorderTasks })(WeeklyView));
