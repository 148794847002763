import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { AddExistingWorkoutTemplate, Tasks_AssignStatus, Tasks_AssignTask, Tasks_GetStatuses, Tasks_GetTasks } from '../../Services/Actions';

import Popover from 'react-popover';
import Select from 'react-select';

import * as styles from './styles';

import AssignClients from '../AssignClients';
// import Loading from '../Loading';
import MultiText from '../Text/MultiText';
import PageText from '../Text/PageText';
import Spacer from '../Spacer';

import { Icon as IconComponent } from '../IconLibrary';
import * as IconsList from '../IconLibrary';

import InfoIcon from '../../Assets/Icons/Info.png';
import PlusSign_White from '../../Assets/Icons/PlusSign_White.png';

import { Rating, Vote } from '../IconLibrary';

const defaultAddingDataInfo = {
    ByDay: [],
    EndDate: '',
    EndingType: null, // "EndDate" or "NumMaxOccurrences"
    Frequency: null,
    Interval: 0,
    NumMaxOccurrences: 0,
    SelectedUsers: [],
    SelectedUserIds: [],
    StartDate: '',
    Statuses: [],
    Tasks: []
};

const AddingTypes = [
    { ImageName: Vote, PopoverTranslation: 'addtaskstatus_task_description', Translation: 'Task', AddingType: 'Tasks' },
    { ImageName: Rating, PopoverTranslation: 'addtaskstatus_status_description', Translation: 'Status', AddingType: 'Statuses' }
];

const DropdownStyles = {
    control: (styles, { isDisabled }) => ({ ...styles, cursor: isDisabled ? 'not-allowed' : 'default', maxWidth: '100%', width: '200px' }),
    input: styles => ({ ...styles, maxWidth: '100%', width: '200px' }),
    menu: styles => ({ ...styles, maxWidth: '100%', width: '200px' }),
    option: (styles, { isDisabled }) => ({ ...styles, cursor: isDisabled ? 'not-allowed' : 'default', maxWidth: '100%', width: '200px' })
};

const PAGE_NO = 1;
const PAGE_SIZE = 1000;

const WeeklyFrequencyDays = [
    { DayId: 'MO', DayName: 'monday_abbr' },
    { DayId: 'TU', DayName: 'tuesday_abbr' },
    { DayId: 'WE', DayName: 'wednesday_abbr' },
    { DayId: 'TH', DayName: 'thursday_abbr' },
    { DayId: 'FR', DayName: 'friday_abbr' },
    { DayId: 'SA', DayName: 'saturday_abbr' },
    { DayId: 'SU', DayName: 'sunday_abbr' }
]

const formatOptionLabel = ({ Icon, StatusName, TaskName }, { context }) => {
    return (
        <styles.DropdownLabelContainer className="DropdownLabelContainer">
            <div><IconComponent src={IconsList[Icon]} /></div>
            <span>{StatusName || TaskName}</span>
        </styles.DropdownLabelContainer>
    );
}

class ButtonWithHoverSpot extends React.Component {
    TimeoutId = null;

    state = { PopoverIsOpen: false };

    onTogglePopover = () => {
        this.setState({ PopoverIsOpen: !this.state.PopoverIsOpen }, () => {
            if (!this.state.PopoverIsOpen && !!this.TimeoutId) clearTimeout(this.TimeoutId);
        });
    }

    renderPopoverContent = () => {
        var { t } = this.props;
        var { PopoverText } = this.props;

        return (
            <styles.PopoverContent className="PopoverContent">
                <PageText FontFamily="medium-italic" FontSize="medium-1" JustifyContent="center" NoMargin Text={t(PopoverText || '')} TextAlign="center" />
            </styles.PopoverContent>
        );
    }

    render() {
        var { t } = this.props;
        var { Device, ImageName, OnClick, PopoverText, Selected, TranslationKey, TranslationKey2 } = this.props;

        const popoverProps = {
            isOpen: this.state.PopoverIsOpen,
            preferPlace: 'below',
            onOuterAction: this.onTogglePopover,
            body: this.renderPopoverContent(),
            tipSize: 0.01
        }

        return (
            <styles.MenuItem onClick={OnClick} Selected={Selected}>
                <styles.MenuItemContainerFirst>
                    {
                        !!PopoverText &&
                        <Popover {...popoverProps}>
                            <styles.HoverInfoIcon ImageSrc={InfoIcon} OnMouseEnter={this.onTogglePopover} OnMouseLeave={this.onTogglePopover} />
                        </Popover>
                    }

                    <styles.MenuItemIllustration alt={t(TranslationKey)} src={ImageName} />
                </styles.MenuItemContainerFirst>

                {(Device === 'ipad' || Device === 'laptop') && <Spacer Size="small" />}

                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent={(Device === 'ipad' || Device === 'laptop') ? 'center' : 'flex-start'} NoMargin Text={t(TranslationKey)} TextAlign={(Device === 'ipad' || Device === 'laptop') ? 'center' : 'left'} />

                {
                    !!TranslationKey2 &&
                    <>
                        <Spacer Size="extra-extra-small" />

                        <PageText FontFamily="medium" FontSize="medium-1" JustifyContent={(Device === 'ipad' || Device === 'laptop') ? 'center' : 'flex-start'} NoMargin Text={t(TranslationKey2)} TextAlign={(Device === 'ipad' || Device === 'laptop') ? 'center' : 'left'} />
                    </>
                }
            </styles.MenuItem>
        );
    }
}

class AddTasksStatuses extends React.Component {
    // topRef = React.createRef();
    
    state = {
        Collapsed: true,
        ScreenNumber: 1,
        AddingType: null,
        AddingData: { ...defaultAddingDataInfo },
        Statuses: [],
        Tasks: []
    }

    componentDidMount() {
        this.addDefaultClients();
    }

    addDefaultClients = () => {
        var { ClientDetails } = this.props;

        var SelectedUserIds = [];
        var SelectedUsers = [];

        if (!!ClientDetails && Object.entries(ClientDetails).length !== 0) {
            SelectedUserIds = [ +ClientDetails.ClientId ];
            SelectedUsers = [{ ...ClientDetails, UserId: +ClientDetails.ClientId }];
        }

        if (!!SelectedUserIds.length) {
            var AddingData = { ...this.state.AddingData, SelectedUserIds, SelectedUsers };
            this.setState({ AddingData });
        }
    }

    onAssignTaskStatus = () => {
        var { AddingType } = this.state;
        var { ByDay, EndDate, Frequency, Interval, NumMaxOccurrences, SelectedUserIds, StartDate } = this.state.AddingData;

        var ClientIds = SelectedUserIds.join(',');

        var RecurrencePattern = '';
        if (Frequency === 'Daily' || Frequency === 'Weekly' || Frequency === 'Monthly') {
            RecurrencePattern += `FREQ=${Frequency.toUpperCase()};`;
            if (Frequency === 'Weekly') RecurrencePattern += `BYDAY=${ByDay.join(',')};`;
            if (Frequency === 'Monthly') RecurrencePattern += `BYMONTHDAY=${this.state.AddingData.StartDate.slice(-2)};`;
            RecurrencePattern += `INTERVAL=${Interval};`;
            RecurrencePattern += `UNTIL=${EndDate}`;
        }

        if (AddingType === 'Statuses') this.props.Tasks_AssignStatus({ ClientIds, EndDate, NumMaxOccurrences, RecurrencePattern, StartDate, StatusIds: this.state.AddingData.Statuses.map(({ StatusId }) => StatusId).join(',') }).then(() => this.onResetWeeklyView());
        else if (AddingType === 'Tasks') this.props.Tasks_AssignTask({ ClientIds, EndDate, NumMaxOccurrences, RecurrencePattern, StartDate, TaskIds: this.state.AddingData.Tasks.map(({ TaskId }) => TaskId).join(',') }).then(() => this.onResetWeeklyView());
    }

    onChangeAddTaskStatus = data => {
        var AddingData = { ...this.state.AddingData };
        AddingData[this.state.AddingType] = data;

        this.setState({ AddingData });
    }

    onChangeAssignTask_ByDay = ({ DayId, SelectedIndex }) => {
        var ByDay = [ ...this.state.AddingData.ByDay ];

        if (SelectedIndex === -1) ByDay = [ ...ByDay, DayId ];
        else ByDay = [ ...ByDay.slice(0, SelectedIndex), ...ByDay.slice(SelectedIndex + 1) ];

        this.setState({ AddingData: { ...this.state.AddingData, ByDay }});
    }

    onChangeAssignTask_Clients = ({ SelectedClients: SelectedUsers }) => {
        var SelectedUserIds_New = SelectedUsers.map(User => User.UserId);
        SelectedUsers = SelectedUsers.map(User => ({ ...User, Sex: User.Gender }));

        this.setState({ AddingData: { ...this.state.AddingData, SelectedUsers, SelectedUserIds: SelectedUserIds_New }});
    }

    onChangeAssignTask_Frequency = Frequency => {
        this.setState({ AddingData: { ...this.state.AddingData, Frequency }});
    }

    onChangeAssignTask_EndDate = event => {
        this.setState({ AddingData: { ...this.state.AddingData, EndDate: event.target.value }});
    }

    onChangeAssignTask_EndingType = EndingType => {
        this.setState({ AddingData: { ...this.state.AddingData, EndingType }});
    }

    onChangeAssignTask_NumMaxOccurrences = event => {
        var NumMaxOccurrences = +event.target.value;
        if (NumMaxOccurrences >= 100) NumMaxOccurrences = 100;

        this.setState({ AddingData: { ...this.state.AddingData, NumMaxOccurrences }});
    }

    onChangeAssignTask_StartDate = event => {
        this.setState({ AddingData: { ...this.state.AddingData, StartDate: event.target.value }});
    }
    
    onChangeScreenNumber = async (Direction, CanGoNext, LastScreen) => {
        var { AddingType, ScreenNumber } = this.state;

        document.getElementsByClassName('AddWorkoutsParentContainer')[0].scrollIntoView();

        // Call API If LastScreen
            if (LastScreen) this.onAssignTaskStatus();
            else {
                ScreenNumber = Direction === 1 ? ScreenNumber + 1 : ScreenNumber - 1;

                if (!!Direction && ScreenNumber === 2) {
                    if (AddingType === 'Tasks' && !this.state.Tasks.length) {
                        var { Tasks } = await this.props.Tasks_GetTasks({ PageNo: PAGE_NO, PageSize: PAGE_SIZE });
                        this.setState({ Tasks });
                    }
                    else if (AddingType === 'Statuses' && !this.state.Statuses.length) {
                        var { Statuses } = await this.props.Tasks_GetStatuses({ PageNo: PAGE_NO, PageSize: PAGE_SIZE });
                        Statuses = Statuses.filter(({ NotSelectable }) => !NotSelectable);
                        this.setState({ Statuses });
                    }
                }

                this.setState({ ScreenNumber });
            }
    }

    onClickAddingType = AddingType => {
        if (this.state.AddingType === AddingType) AddingType = null;

        this.setState({ AddingType }, () => {
            if (!!AddingType) this.onChangeScreenNumber(1);
        });
    }

    onResetWeeklyView = () => {
        var AddingData = { ...this.state.AddingData };

        this.setState({
            Collapsed: true,
            ScreenNumber: 1,
            AddingType: null,
            AddingData: { ...defaultAddingDataInfo }
            }, () => {
                this.addDefaultClients();
                this.props.OnAddedTasksStatuses({ ClientIds: AddingData.SelectedUserIds });
            });
    }

    onToggleCollapsed = () => {
        this.setState({ Collapsed: !this.state.Collapsed });
    }

    renderAddingTypes = () => {
        var { t } = this.props;
        var { Device } = this.props;
        var { AddingType } = this.state;

        return (
            <>
                <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="flex-start" NoMargin Text={t('addtaskstatus_whattype')} TextAlign="left" />

                <Spacer Size="extra-small" />

                <styles.MenuOptionsContainer>
                    {
                        AddingTypes.map(({ ImageName, Translation, AddingType: MappedAddingType }, index) => {
                            return (
                                <ButtonWithHoverSpot
                                    key={index}
                                    t={t}
                                    Device={Device}
                                    ImageName={ImageName}
                                    OnClick={() => this.onClickAddingType(MappedAddingType)}
                                    Selected={MappedAddingType === AddingType}
                                    TranslationKey={Translation}
                                />
                            );
                        })
                    }
                </styles.MenuOptionsContainer>
            </>
        );
    }

    renderAssigningTo = () => {
        var { t } = this.props;
        var { AddingType, Statuses, Tasks } = this.state;
        var { ByDay, EndDate, EndingType, Frequency, NumMaxOccurrences, SelectedUsers, SelectedUserIds, StartDate } = this.state.AddingData;

        var options = AddingType === 'Tasks' ? Tasks : Statuses;

        var Today = new Date();

        var MinDate = Today.toJSON().slice(0,10);
        var MaxDate = new Date(Today.setFullYear(Today.getFullYear() + 1)).toJSON().slice(0,10);
        var MinDate_EndDate = StartDate;

        return (
            <>
                <styles.AssigningToThreeColumnsContainer className="AssigningToThreeColumnsContainer">
                    <styles.AssigningToColumnOne className="AssigningToColumnOne">
                        <PageText FontFamily="medium" FontSize="medium-3" JustifyContent="flex-start" NoMargin Text={t('assigned_to')} TextAlign="left" />
                            
                        <Spacer Size="extra-small" />

                        <AssignClients
                            HideTitle
                            HorizontalScroll={false}
                            LeftAlign
                            OnSelectClients={Users => this.onChangeAssignTask_Clients(Users)}
                            SelectedUserIds={SelectedUserIds}
                            SelectedUsers={SelectedUsers}
                            WhiteBackground
                        />
                    </styles.AssigningToColumnOne>
                    <styles.AssigningToColumnTwo className="AssigningToColumnTwo">
                        <styles.SelectedDatesContainer className="SelectedDatesContainer">
                            <PageText FontFamily="medium" FontSize="medium-3" JustifyContent="flex-start" NoMargin Text={t('choose_dates')} TextAlign="left" />
                            
                            <Spacer Size="extra-small" />
                            
                            {/* Start Date */}
                                <MultiText
                                    TextAlign="left"
                                    Texts={[
                                        { FontFamily: 'medium', FontSize: 'medium-2', Text: t('start_date') },
                                        { FontColor: 'red-bittersweet', FontFamily: 'medium', FontSize: 'medium-2', Text: '*' },
                                    ]}
                                />

                                <styles.StyledAccountInput
                                    FontSize="medium-1"
                                    Min={MinDate}
                                    OnChange={this.onChangeAssignTask_StartDate}
                                    Size="large"
                                    Type="date"
                                    Value={StartDate}
                                />

                            {/* Repeating */}
                                {/* Frequency */}
                                    <MultiText
                                        TextAlign="left"
                                        Texts={[
                                            { FontFamily: 'medium', FontSize: 'medium-2', Text: t('tasks_want_to_repeat') },
                                            { FontColor: 'red-bittersweet', FontFamily: 'medium', FontSize: 'medium-2', Text: '*' },
                                        ]}
                                    />

                                    <styles.RadioButtonParentContainer className="RadioButtonParentContainer">
                                        <styles.PseudoCheckbox className="PseudoCheckbox" BackgroundColor="274775" onClick={() => this.onChangeAssignTask_Frequency('Daily')} Selected={Frequency === 'Daily'} />

                                        <styles.RadioButtonPageText ElementType="span" FontFamily={Frequency === 'Daily' ? 'semibold' : 'medium'} FontSize="medium-1" JustifyContent="flex-start" OnClick={() => this.onChangeAssignTask_Frequency('Daily')} Text={t('daily')} TextAlign="left" />
                                    </styles.RadioButtonParentContainer>

                                    <styles.RadioButtonParentContainer className="RadioButtonParentContainer">
                                        <styles.PseudoCheckbox className="PseudoCheckbox" BackgroundColor="274775" onClick={() => this.onChangeAssignTask_Frequency('Weekly')} Selected={Frequency === 'Weekly'} />

                                        <styles.RadioButtonPageText ElementType="span" FontFamily={Frequency === 'Weekly' ? 'semibold' : 'medium'} FontSize="medium-1" JustifyContent="flex-start" OnClick={() => this.onChangeAssignTask_Frequency('Weekly')} Text={t('weekly')} TextAlign="left" />
                                    </styles.RadioButtonParentContainer>

                                    {
                                        Frequency === 'Weekly' &&
                                        <>
                                            <Spacer Size="extra-extra-small" />

                                            <styles.WeeklyFrequencyDaysContainer className="WeeklyFrequencyDaysContainer">
                                                {
                                                    WeeklyFrequencyDays.map(({ DayId, DayName }) => {
                                                        var SelectedIndex = ByDay.indexOf(DayId);
                                                        var Selected = SelectedIndex !== -1;

                                                        return (
                                                            <styles.WeeklyFrequencyDayContainer className="WeeklyFrequencyDayContainer" key={DayId}>
                                                                <PageText FontColor={Selected ? 'blue-abel' : 'black'} FontFamily={Selected ? 'semibold' : 'medium'} FontSize="medium-1" JustifyContent="flex-start" NoMargin OnClick={() => this.onChangeAssignTask_ByDay({ DayId, SelectedIndex })} Text={t(DayName)} TextAlign="left" />
                                                            </styles.WeeklyFrequencyDayContainer>
                                                        );
                                                    })
                                                }
                                            </styles.WeeklyFrequencyDaysContainer>
                                            
                                            <Spacer Size="extra-extra-small" />
                                        </>
                                    }

                                    <styles.RadioButtonParentContainer className="RadioButtonParentContainer">
                                        <styles.PseudoCheckbox className="PseudoCheckbox" BackgroundColor="274775" onClick={() => this.onChangeAssignTask_Frequency('Monthly')} Selected={Frequency === 'Monthly'} />

                                        <styles.RadioButtonPageText ElementType="span" FontFamily={Frequency === 'Monthly' ? 'semibold' : 'medium'} FontSize="medium-1" JustifyContent="flex-start" OnClick={() => this.onChangeAssignTask_Frequency('Monthly')} Text={t('monthly')} TextAlign="left" />
                                    </styles.RadioButtonParentContainer>

                                    {
                                        Frequency === 'Monthly' &&
                                        <>
                                            <Spacer Size="extra-extra-small" />

                                            <PageText FontFamily="medium-italic" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('_monthly_date_is_determined_by_startdate')} TextAlign="left" />

                                            <Spacer Size="extra-extra-small" />
                                        </>
                                    }

                                    <styles.RadioButtonParentContainer className="RadioButtonParentContainer">
                                        <styles.PseudoCheckbox className="PseudoCheckbox" BackgroundColor="274775" onClick={() => this.onChangeAssignTask_Frequency('None')} Selected={Frequency === 'None'} />

                                        <styles.RadioButtonPageText ElementType="span" FontFamily={Frequency === 'None' ? 'semibold' : 'medium'} FontSize="medium-1" JustifyContent="flex-start" OnClick={() => this.onChangeAssignTask_Frequency('None')} Text={t('no_repeat')} TextAlign="left" />
                                    </styles.RadioButtonParentContainer>

                                {/* Ending Type */}
                                    {
                                        (Frequency === 'Daily' || Frequency === 'Weekly' || Frequency === 'Monthly') &&
                                        <>
                                            <Spacer Size="medium" />

                                            <MultiText
                                                TextAlign="left"
                                                Texts={[
                                                    { FontFamily: 'medium', FontSize: 'medium-2', Text: t('taskstatus_repeat_endingtype') },
                                                    { FontColor: 'red-bittersweet', FontFamily: 'medium', FontSize: 'medium-2', Text: '*' },
                                                ]}
                                            />

                                            <styles.RadioButtonParentContainer className="RadioButtonParentContainer">
                                                <styles.PseudoCheckbox className="PseudoCheckbox" BackgroundColor="274775" onClick={() => this.onChangeAssignTask_EndingType('EndDate')} Selected={EndingType === 'EndDate'} />

                                                <styles.RadioButtonPageText ElementType="span" FontFamily={EndingType === 'EndDate' ? 'semibold' : 'medium'} FontSize="medium-1" JustifyContent="flex-start" OnClick={() => this.onChangeAssignTask_EndingType('EndDate')} Text={t('end_date')} TextAlign="left" />
                                            </styles.RadioButtonParentContainer>

                                            {/* End Date */}
                                                {
                                                    EndingType === 'EndDate' &&
                                                    <styles.StyledAccountInput
                                                        Disabled={!StartDate}
                                                        FontSize="medium-1"
                                                        Max={MaxDate}
                                                        Min={MinDate_EndDate}
                                                        OnChange={this.onChangeAssignTask_EndDate}
                                                        Size="large"
                                                        Type="date"
                                                        Value={EndDate}
                                                    />
                                                }

                                            <styles.RadioButtonParentContainer className="RadioButtonParentContainer">
                                                <styles.PseudoCheckbox className="PseudoCheckbox" BackgroundColor="274775" onClick={() => this.onChangeAssignTask_EndingType('NumMaxOccurrences')} Selected={EndingType === 'NumMaxOccurrences'} />

                                                <styles.RadioButtonPageText ElementType="span" FontFamily={EndingType === 'NumMaxOccurrences' ? 'semibold' : 'medium'} FontSize="medium-1" JustifyContent="flex-start" OnClick={() => this.onChangeAssignTask_EndingType('NumMaxOccurrences')} Text={t('num_max_occurrences')} TextAlign="left" />
                                            </styles.RadioButtonParentContainer>

                                            {/* Num Max Occurrences */}
                                                {
                                                    EndingType === 'NumMaxOccurrences' &&
                                                    <styles.StyledAccountInput
                                                        FontSize="medium-1"
                                                        Max={100}
                                                        Min={1}
                                                        OnChange={this.onChangeAssignTask_NumMaxOccurrences}
                                                        Size="large"
                                                        Type="number"
                                                        Value={NumMaxOccurrences}
                                                    />
                                                }
                                        </>
                                    }
                        </styles.SelectedDatesContainer>
                    </styles.AssigningToColumnTwo>
                    <styles.AssigningToColumnThree className="AssigningToColumnThree">
                        <styles.InputLabelOptionalContainer className="InputLabelOptionalContainer">
                            <PageText FontFamily="medium" FontSize="medium-3" JustifyContent="flex-start" NoMargin Text={t('_select')} TextAlign="left" />
                        </styles.InputLabelOptionalContainer>
                        
                        <Spacer Size="extra-small" />

                        <Select
                            closeMenuOnSelect={false}
                            getOptionLabel={option => AddingType === 'Tasks' ? option.TaskName : option.StatusName}
                            getOptionValue={option => AddingType === 'Tasks' ? option.TaskId : option.StatusId}
                            onChange={this.onChangeAddTaskStatus}
                            isMulti
                            isSearchable
                            options={options}
                            placeholder={t('_select')}
                            formatOptionLabel={formatOptionLabel}
                            styles={DropdownStyles}
                            // value={ActivityId && { value: ActivityId, label: ActivityName }}
                        />
                    </styles.AssigningToColumnThree>
                </styles.AssigningToThreeColumnsContainer>
            </>
        );
    }

    renderBody = () => {
        var { ScreenNumber } = this.state;

        if (ScreenNumber === 1) return this.renderAddingTypes();
        else if (ScreenNumber === 2) return this.renderAssigningTo();

        return null;
    }

    renderBottomButtons = () => {
        var { t } = this.props;
        var { AddingData, AddingType, ScreenNumber } = this.state;
        var { ByDay, EndDate, EndingType, Frequency, NumMaxOccurrences, SelectedUserIds, StartDate } = AddingData;

        var CanGoNext = 1;
        var LastScreen = 0;

        if (ScreenNumber === 1 && !AddingType) CanGoNext = 0;
        else if (ScreenNumber === 2) {
            if (AddingType === 'Tasks' && (!AddingData.Tasks || !AddingData.Tasks.length)) CanGoNext = 0;
            else if (AddingType === 'Statuses' && (!AddingData.Statuses || !AddingData.Statuses.length)) CanGoNext = 0;
            else if (!StartDate || SelectedUserIds.length === 0 || !Frequency || (Frequency === 'Weekly' && ByDay.length === 0)) CanGoNext = 0;
            else if (((Frequency === 'Daily' || Frequency === 'Weekly' || Frequency === 'Monthly') && !EndingType) || ((Frequency === 'Daily' || Frequency === 'Weekly' || Frequency === 'Monthly') && EndingType === 'EndDate' && !EndDate) || ((Frequency === 'Daily' || Frequency === 'Weekly' || Frequency === 'Monthly') && EndingType === 'NumMaxOccurrences' && !NumMaxOccurrences)) CanGoNext = 0;

            LastScreen = 1;
        }

        return (
            <styles.BackNextButtons
                Buttons={[
                    { BackgroundColor: 'blue-astronaut', BackgroundColorHover: 'blue-astronaut-hover', Border: 'none', Color: 'white', ColorHover: 'white', Disabled: ScreenNumber === 1, FontFamily: 'semibold', OnClick: () => this.onChangeScreenNumber(-1), Title: t('back_with_first_char_uppercase') },
                    { BackgroundColor: 'abel-blue', BackgroundColorHover: 'abel-blue-hover', Border: 'none', Color: 'white', ColorHover: 'white', Disabled: !CanGoNext, FontFamily: 'semibold', OnClick: () => this.onChangeScreenNumber(1, CanGoNext, LastScreen), Title: t(LastScreen ? 'finish_with_first_char_uppercase' : 'next_with_first_char_uppercase') }
                ]}
            />
        );
    }

    render() {
        var { t } = this.props;
        // var { Collapsed, ScreenNumber, WorkoutType } = this.state;
        var { Collapsed } = this.state;

        return (
            <>
                {/* {(((ScreenNumber === 2 && WorkoutType === 'Activity') || (ScreenNumber === 3 && (WorkoutType === 'Endurance' || WorkoutType === 'Strength'))) && TryingGetAdditionalActivityDetails) && <Loading />} */}

                <styles.AddWorkoutsOuterContainer
                    className="AddWorkoutsOuterContainer"
                    // ref={this.topRef}
                    Collapsed={Collapsed}
                >
                    {
                        !!Collapsed ?
                        <styles.AddWorkoutsCollapsedContainer className="AddWorkoutsCollapsedContainer" onClick={this.onToggleCollapsed}>
                            <styles.AddWorkoutsCollapsedIcon ImageSrc={PlusSign_White} />
                            <PageText FontColor="white" FontFamily="medium" FontSize="medium-3" JustifyContent="flex-start" NoMargin Text={t('add_tasks_statuses_to_the_calendar')} TextAlign="left" />
                        </styles.AddWorkoutsCollapsedContainer>
                    :
                        <>
                            <styles.AddWorkoutsParentContainer className="AddWorkoutsParentContainer">
                                <styles.AddWorkoutsTopHeaderContainer className="AddWorkoutsTopHeaderContainer">
                                    <styles.AddWorkoutsTopHeaderContainerOne className="AddWorkoutsTopHeaderContainerOne">
                                        <PageText FontFamily="medium" FontSize="medium-3" JustifyContent="flex-start" NoMargin OnClick={this.onToggleCollapsed} Text={t('add_tasks_statuses_to_the_calendar')} TextAlign="left" />
                                    </styles.AddWorkoutsTopHeaderContainerOne>
                                    <styles.AddWorkoutsTopHeaderContainerTwo className="AddWorkoutsTopHeaderContainerTwo">
                                    
                                    </styles.AddWorkoutsTopHeaderContainerTwo>
                                </styles.AddWorkoutsTopHeaderContainer>

                                <styles.StyledHR />

                                <styles.AddWorkoutsBodyContainer className="AddWorkoutsBodyContainer">
                                    {this.renderBody()}
                                </styles.AddWorkoutsBodyContainer>
                            </styles.AddWorkoutsParentContainer>

                            {this.renderBottomButtons()}
                        </>   
                    }
                </styles.AddWorkoutsOuterContainer>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,
        UserDetails: state.Auth.UserDetails,

        TryingTasksAction: state.Tasks.TryingTasksAction,
        TryingTasksActionError: state.Tasks.TryingTasksActionError
    };
};

export default withTranslation()(connect(mapStateToProps, { AddExistingWorkoutTemplate, Tasks_AssignStatus, Tasks_AssignTask, Tasks_GetStatuses, Tasks_GetTasks } )(AddTasksStatuses));