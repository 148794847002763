import styled from 'styled-components';

import ButtonGroup from '../../Components/Buttons/ButtonGroup';
import Image from '../../Components/Image';
import Input from '../../Components/Form/Input';
import PageText from '../../Components/Text/PageText';

import DeleteX from '../../Assets/Icons/Delete_X.png';
import DeleteX_Gray from '../../Assets/Icons/Delete_X_Gray.png';

export const AddJournalEntryContainer = styled.div`
    align-items: stretch;
    background-color: var(--white-concrete);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px 15px;
`;

export const ButtonIcon = styled.img`
    height: 25px;
    margin-right: 10px;
    max-width: 25px;
`;

export const JournalEntriesContainer = styled.div`
    display: grid;
    grid-gap: 15px;
`;

export const JournalEntryContainer = styled.div`
    background-color: var(--white-concrete);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 10px 15px;
`;

export const JournalEntryMediaContainer = styled.div``;

export const JournalEntryMediaListContainer = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;

    > div {
        margin: 5px;
    }
`;

export const JournalEntryMediaParentContainer = styled.div``;

export const JournalEntryVideoCall = styled.span`
    font-family: var(--font-family-semibold);
    font-size: var(--font-size-medium-1);
`;

export const StyledExternalLink = styled.a`
    margin: 5px;
`;

export const MultiSelect = styled.span`
    ${props => props.Selected ? `background-image: url(${DeleteX});` : `background-image: url(${DeleteX_Gray});`}

    background-color: var(--white);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 20px;
    border-radius: 50%;
    height: 20px;
    right: 5px;
    position: absolute;
    top: 5px;
    width: 20px;
    z-index: 1;

    @media(hover:hover) {
        :hover {
            background-image: url(${DeleteX});
            background-position: center;
            background-repeat: no-repeat;
            background-size: 20px;
            border: none;
            cursor: pointer;
        }
    }
`;

export const StyledDuration = styled(Input)`
    max-width: 100%;
    width: 100px;

    > input {
        text-align: center;
        text-indent: 0px;
    }
`;

export const StyledHR = styled.div`
    background-color: var(--white);
    border-radius: 10px;
    height: 2px;
    margin: 10px -15px;
`;

export const StyledMediaDocumentPreview = styled.div`
    align-items: center;
    background-color: var(--white);
    border: 2px solid var(--white-concrete);
    border-radius: 10px;
    color: var(--black);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-height: 45px;
    padding: 7px;
    padding-right: 30px;
    position: relative;
    
    @media(hover:hover) {
        :hover {
            color: var(--link-hover);
            cursor: pointer;
        }
    }

    :active {
        color: var(--blue-abel);
    }
`;

export const MediaPreview = styled.div`
    position: relative;
`;

export const PseudoCheckbox = styled.div`
    ${
        props => props.Selected && `background-color: #${props.BackgroundColor};`
    }

    border: 2px solid #274775;
    border-radius: 5px;
    height: 25px;
    min-width: 25px;
    width: 25px;

    @media(hover:hover) {
        :hover {
            ${props => `background-color: #${props.BackgroundColor};`}
            cursor: pointer;
        }
    }
`;

export const RadioButtonPageText = styled(PageText)`
    @media(hover:hover) {
        :hover {
            ${props => !props.ReadOnly ? `cursor: pointer;` : `cursor: not-allowed;`}
        }
    }
`;

export const RadioButtonParentContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: flex-start;
    margin-top: 5px;

    > div:first-child {
        margin-right: 5px;
    }
`;

export const StyledMediaPreview = styled(Image)`
    background-color: var(--white);
    border-radius: 10px;
    height: 45px;
    object-fit: cover;
    width: 90px;

    > div {
        width: 100%;
    }
`;

export const StyledVideoPreview = styled.video`
    background-color: var(--white);
    border: 2px solid var(--black);
    border-radius: 10px;
    height: 45px;
    object-fit: cover;
    width: 90px;

    > div {
        width: 100%;
    }

    @media(hover:hover) {
        :hover {
            cursor: pointer;
        }
    }
`;

export const UploadButtonsContainer = styled(ButtonGroup)`
    justify-content: space-between;
`;

export const UploadContainer = styled.div`
    margin-top: 10px;
`;

export const UploadButtonsRow = styled.div`
    margin-bottom: 10px;
`;

export const UploadDocumentsContainer = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;

    > div {
        margin: 5px;
    }
`;