import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { GetClientDetails, GetGarminData_BodyBattery, GetGarminData_Sleep, GetGarminData_Stress } from '../../Services/Actions';
import history from '../../history';

import moment from 'moment';

import Languages from '../../Config/languages';

import  { BodyGoalChartContainer, BodyGoalChartHeaderContainer, BodyGoalChartHeaderDateContainer, BodyGoalChartHeaderTitleContainer, BodyGoalLineGraphContainer, CalendarIcon, ChartDateCustomContainer, ChartDateCustomTextContainer, ChartDatesPreMadeContainer, LinkInnerContainer, NoMeasurementsContainer, StyledHR, TimePeriodContainer, TimePeriodCheckmark } from './styles';

import ButtonGroup from '../../Components/Buttons/ButtonGroup';
import LineGraph from '../../Components/Charts/LineGraph';
import Loading from '../../Components/Loading';
import Page from '../../Components/Page';
import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';

import { SecondsToTime } from '../../Functions';

import ChooseDates from '../../Modals/ChooseDates';

import Calendar from '../../Assets/Icons/Calendar.png';
import { ReactComponent as GarminSVG } from '../../Assets/SVG/Garmin.svg';

const BASE_CHART_DATA = {
    Goals: [],
    Increment: 0,
    Maximum: 0,
    Measurements: [],
    Measurements_Max: [],
    Measurements_Min: [],
    Minimum: 0
}

const BASE_CHART_METADATA = {
    CurrentValue: 0,
    HasData: false,
    StartValue: 0,
    SubTitle: '',
    Title: ''
}

const RoundFunc = NumberToRound => {
    return Math.round(NumberToRound * 100) / 100;
}

class GarminProgress extends React.PureComponent {
    _isMounted = false;

    state = {
        ChartEndDate: null,
        ChartStartDate: null,
        DataType: null,
        DateFormat: 'MM-DD-YYYY',
        HasData: false,
        Loading: true,
        ShowChooseDatesModal: false,
        TimeFormat: 'hh:mm a',
        TimePeriod: '1W',
        Title: '',

        Chart: { ...BASE_CHART_DATA },
        ...BASE_CHART_METADATA
    };

    componentDidMount() {
        this._isMounted = true;

        var { ClientId, DataType } = this.props.match.params;
        var { LanguageId } = this.props.UserDetails;

        DataType = DataType.toLowerCase();

        if (!+ClientId || !DataType) history.push('/');
        else {
                this.props.GetClientDetails(ClientId).then(() => {
                    this.setState({
                        DataType,
                        DateFormat: Languages[`${LanguageId}`] ? Languages[`${LanguageId}`].longDateFormat["[NumbersDate]"] : Languages[`1`].longDateFormat["[NumbersDate]"],
                        TimeFormat: Languages[`${LanguageId}`] ? Languages[`${LanguageId}`].longDateFormat["[TrackingHistoryTime]"] : Languages[`1`].longDateFormat["[TrackingHistoryTime]"]
                    }, () => {
                        if (DataType === 'bodybattery') this.onViewBodyBattery({});
                        else if (DataType === 'sleep') this.onViewSleep({});
                        else if (DataType === 'stress') this.onViewStress({});
                        else this.setState({ Loading: false });
                    });
                });
        }
    }
    
    componentWillUnmount() {
        this._isMounted = false;
    }

    onSelectCustomDates = ({ EndDate, StartDate }) => {
        this.onToggleShowChooseDatesModal(false);

        this.onUpdateChartDates({ ChartEndDate: EndDate, ChartStartDate: StartDate });
    }

    onSelectTimePeriod = ({ TimePeriod }) => {
        if (TimePeriod !== this.state.TimePeriod) {
            this.onUpdateChartDates({ TimePeriod })
        }
    }

    onToggleShowChooseDatesModal = ShowChooseDatesModal => {
        this.setState({ ShowChooseDatesModal });
    }

    onUpdateChartDates = ({ ChartEndDate = null, ChartStartDate = null, TimePeriod = null }) => {
        if (TimePeriod === '1W') { ChartEndDate = `${moment().format('YYYY-MM-DD')}T23:59:59.999Z`; ChartStartDate = `${moment().subtract(1, 'weeks').format('YYYY-MM-DD')}T00:00:00.000Z`; }
        else if (TimePeriod === '1M') { ChartEndDate = `${moment().format('YYYY-MM-DD')}T23:59:59.999Z`; ChartStartDate = `${moment().subtract(4, 'weeks').format('YYYY-MM-DD')}T00:00:00.000Z`; }
        else if (TimePeriod === '1Y') { ChartEndDate = `${moment().format('YYYY-MM-DD')}T23:59:59.999Z`; ChartStartDate = `${moment().subtract(1, 'years').format('YYYY-MM-DD')}T00:00:00.000Z`; }
        else { ChartEndDate = `${moment(ChartEndDate).format('YYYY-MM-DD')}T23:59:59.999Z`; ChartStartDate = `${moment(ChartStartDate).format('YYYY-MM-DD')}T00:00:00.000Z`; }

        this.setState({ ChartEndDate, ChartStartDate, TimePeriod }, () => {
            var { DataType } = this.state;

            if (DataType === 'bodybattery') this.onViewBodyBattery({ ChartEndDate, ChartStartDate });
            else if (DataType === 'sleep') this.onViewSleep({ ChartEndDate, ChartStartDate });
            else if (DataType === 'stress') this.onViewStress({ ChartEndDate, ChartStartDate });
        });
    }

    onViewBodyBattery = ({ ChartEndDate = null, ChartStartDate = null }) => {
        var { t } = this.props;
        var { ClientId } = this.props.match.params;
        var { TimePeriod } = this.state;

        if (!ChartEndDate || !ChartStartDate) {
            if (TimePeriod === '1W') { ChartEndDate = `${moment().format('YYYY-MM-DD')}T23:59:59.999Z`; ChartStartDate = `${moment().subtract(1, 'weeks').format('YYYY-MM-DD')}T00:00:00.000Z`; }
            else if (TimePeriod === '1M') { ChartEndDate = `${moment().format('YYYY-MM-DD')}T23:59:59.999Z`; ChartStartDate = `${moment().subtract(4, 'weeks').format('YYYY-MM-DD')}T00:00:00.000Z`; }
            else if (TimePeriod === '1Y') { ChartEndDate = `${moment().format('YYYY-MM-DD')}T23:59:59.999Z`; ChartStartDate = `${moment().subtract(1, 'years').format('YYYY-MM-DD')}T00:00:00.000Z`; }
        }

        this.props.GetGarminData_BodyBattery({ EndDate: ChartEndDate, StartDate: ChartStartDate, UserId: ClientId }).then(({ result: Measurements, increment: Increment, minimum: Minimum, maximum: Maximum }) => {
            var BodyBattery = {};
            BodyBattery.Chart = { ...BASE_CHART_DATA, Increment, Maximum, Minimum };

            if (Measurements.length > 0) {
                BodyBattery.HasData = true;

                BodyBattery.Chart.Measurements_Max = [];
                BodyBattery.Chart.Measurements_Min = [];

                for (var i = 0; i < Measurements.length; i++) {
                    var { measurementMaximum, measurementMinimum, measurementDate: MeasurementDate } = Measurements[i];

                    BodyBattery.Chart.Measurements_Max.push({ Measurement: measurementMaximum, MeasurementDate, Units: '' });
                    BodyBattery.Chart.Measurements_Min.push({ Measurement: measurementMinimum, MeasurementDate, Units: '' });
                }

                BodyBattery.CurrentValue = Measurements[Measurements.length - 1].measurementMaximum; // Show Daily Max
                BodyBattery.StartValue = Measurements[Measurements.length - 1].measurementMinimum; // Show Daily Min
                BodyBattery.SubTitle = moment(Measurements[Measurements.length - 1].measurementDate).format('[NumbersDate]'); // Show Most Recently Synced Date
                BodyBattery.Title = t('body_battery');
            }

            this.setState({ ...BodyBattery, Loading: false });
        });
    }

    onViewSleep = ({ ChartEndDate = null, ChartStartDate = null }) => {
        var { t } = this.props;
        var { ClientId } = this.props.match.params;
        var { TimePeriod } = this.state;

        if (!ChartEndDate || !ChartStartDate) {
            if (TimePeriod === '1W') { ChartEndDate = `${moment().format('YYYY-MM-DD')}T23:59:59.999Z`; ChartStartDate = `${moment().subtract(1, 'weeks').format('YYYY-MM-DD')}T00:00:00.000Z`; }
            else if (TimePeriod === '1M') { ChartEndDate = `${moment().format('YYYY-MM-DD')}T23:59:59.999Z`; ChartStartDate = `${moment().subtract(4, 'weeks').format('YYYY-MM-DD')}T00:00:00.000Z`; }
            else if (TimePeriod === '1Y') { ChartEndDate = `${moment().format('YYYY-MM-DD')}T23:59:59.999Z`; ChartStartDate = `${moment().subtract(1, 'years').format('YYYY-MM-DD')}T00:00:00.000Z`; }
        }

        this.props.GetGarminData_Sleep({ EndDate: ChartEndDate, StartDate: ChartStartDate, UserId: ClientId }).then(({ result: Measurements, increment: Increment, minimum: Minimum, maximum: Maximum }) => {
            var Sleep = {};
            Sleep.Chart = { ...BASE_CHART_DATA, Increment, Maximum, Minimum };

            if (Measurements.length > 0) {
                Sleep.HasData = true;

                Sleep.Chart.Measurements = Measurements.map(({ measurement: Measurement, measurementDate: MeasurementDate }) => ({ IsTime: true, Measurement, MeasurementDate, Units: 'hours' }));

                Sleep.CurrentValue = SecondsToTime(Sleep.Chart.Measurements[Sleep.Chart.Measurements.length - 1].Measurement); // Show Most Recently Synced Amount
                Sleep.SubTitle = moment(Sleep.Chart.Measurements[Sleep.Chart.Measurements.length - 1].MeasurementDate).format('[NumbersDate]'); // Show Most Recently Synced Date
                Sleep.Title = t('sleep');
            }

            this.setState({ ...Sleep, Loading: false });
        });
    }

    onViewStress = ({ ChartEndDate = null, ChartStartDate = null }) => {
        var { t } = this.props;
        var { ClientId } = this.props.match.params;
        var { TimePeriod } = this.state;

        if (!ChartEndDate || !ChartStartDate) {
            if (TimePeriod === '1W') { ChartEndDate = `${moment().format('YYYY-MM-DD')}T23:59:59.999Z`; ChartStartDate = `${moment().subtract(1, 'weeks').format('YYYY-MM-DD')}T00:00:00.000Z`; }
            else if (TimePeriod === '1M') { ChartEndDate = `${moment().format('YYYY-MM-DD')}T23:59:59.999Z`; ChartStartDate = `${moment().subtract(4, 'weeks').format('YYYY-MM-DD')}T00:00:00.000Z`; }
            else if (TimePeriod === '1Y') { ChartEndDate = `${moment().format('YYYY-MM-DD')}T23:59:59.999Z`; ChartStartDate = `${moment().subtract(1, 'years').format('YYYY-MM-DD')}T00:00:00.000Z`; }
        }

        this.props.GetGarminData_Stress({ EndDate: ChartEndDate, StartDate: ChartStartDate, UserId: ClientId }).then(({ result: Measurements, increment: Increment, minimum: Minimum, maximum: Maximum }) => {
            var Stress = {};
            Stress.Chart = { ...BASE_CHART_DATA, Increment, Maximum, Minimum };

            if (Measurements.length > 0) {
                Stress.HasData = true;

                Stress.Chart.Measurements = Measurements.map(({ measurement: Measurement, measurementDate: MeasurementDate }) => ({ IsTime: true, Measurement: RoundFunc(Measurement), MeasurementDate, Units: 'hours' }));

                Stress.CurrentValue = SecondsToTime(Stress.Chart.Measurements[Stress.Chart.Measurements.length - 1].Measurement); // Show Most Recently Synced Amount
                Stress.SubTitle = moment(Stress.Chart.Measurements[Stress.Chart.Measurements.length - 1].MeasurementDate).format('[NumbersDate]'); // Show Most Recently Synced Date
                Stress.Title = t('stress');
            }

            this.setState({ ...Stress, Loading: false });
        });
    }

    renderChart = () => {
        var { t } = this.props;
        var { Device } = this.props;
        var { Chart, ChartEndDate, ChartStartDate, DataType, SubTitle, TimePeriod: StateTimePeriod, Title } = this.state;

        var PreMadeDates = [
            { TimePeriod: '1W', TimePeriodName: t('timeperiod_name_one_week') },
            { TimePeriod: '1M', TimePeriodName: t('timeperiod_name_one_month') },
            { TimePeriod: '1Y', TimePeriodName: t('timeperiod_name_one_year') }
        ];

        var JustifyContent = Device === 'ipad' || Device === 'laptop' ? 'flex-start' : 'center';
        var TextAlign = Device === 'ipad' || Device === 'laptop' ? 'left' : 'center';

        var DisplayMinMaxMeasurements = DataType === 'bodybattery' ? true : false;
        var IsTime = DataType === 'sleep';
        var ToolTipConvertXDate = true;
        var ToolTipConvertYToSeconds = DataType === 'sleep' ? true : false;

        return (
            <BodyGoalChartContainer className="BodyGoalChartContainer">
                <BodyGoalChartHeaderContainer className="BodyGoalChartHeaderContainer">
                    <BodyGoalChartHeaderTitleContainer className="BodyGoalChartHeaderTitleContainer">
                        <LinkInnerContainer className="LinkInnerContainer">
                            <GarminSVG />

                            <PageText FontFamily="semibold" FontSize="medium-3" JustifyContent={JustifyContent} NoMargin Text={Title} TextAlign={TextAlign} WordBreak="keep-all" />
                        </LinkInnerContainer>

                        <Spacer Size="extra-extra-small" />
                    
                        <PageText FontFamily="medium" FontSize="medium-1" JustifyContent={JustifyContent} NoMargin Text={`${t('last_synced')}: ${SubTitle}`} TextAlign={TextAlign} WordBreak="keep-all" />
                    </BodyGoalChartHeaderTitleContainer>
                    <BodyGoalChartHeaderDateContainer className="BodyGoalChartHeaderDateContainer">
                        <ChartDatesPreMadeContainer className="ChartDatesPreMadeContainer">
                            {
                                PreMadeDates.map(({ TimePeriod, TimePeriodName }, TimePeriodIndex) => {
                                    var Selected = TimePeriod === StateTimePeriod;

                                    return (
                                        <TimePeriodContainer className="TimePeriodContainer" key={TimePeriodIndex}>
                                            <TimePeriodCheckmark onClick={() => this.onSelectTimePeriod({ TimePeriod })} Selected={Selected} />

                                            <PageText ElementType="span" FontFamily="medium-italic" FontSize="medium-1" OnClick={() => this.onSelectTimePeriod({ TimePeriod })} Text={TimePeriodName} TextAlign="left" />
                                        </TimePeriodContainer>
                                    );
                                })
                            }
                        </ChartDatesPreMadeContainer>
                        <ChartDateCustomContainer className="ChartDateCustomContainer" onClick={() => this.onToggleShowChooseDatesModal(true)} Selected={!StateTimePeriod}>
                            <ChartDateCustomTextContainer className="ChartDateCustomTextContainer">
                                <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={`${t('custom_date_range')}:`} TextAlign="left" WordBreak="keep-all" />&nbsp;
                                {
                                    StateTimePeriod ?
                                    <PageText ElementType="span" FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('none_with_first_char_uppercase')} TextAlign="left" WordBreak="keep-all" />
                                :
                                    <PageText ElementType="span" FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={`${moment(ChartStartDate).format('[WeeklyViewDayHeader]')} - ${moment(ChartEndDate).format('[WeeklyViewDayHeader]')}`} TextAlign="left" WordBreak="keep-all" />
                                }
                            </ChartDateCustomTextContainer>

                            <CalendarIcon ImageSrc={Calendar} />
                        </ChartDateCustomContainer>
                    </BodyGoalChartHeaderDateContainer>
                </BodyGoalChartHeaderContainer>

                <StyledHR />

                <BodyGoalLineGraphContainer className="BodyGoalLineGraphContainer">
                    <LineGraph
                        DisplayMinMaxMeasurements={DisplayMinMaxMeasurements}
                        EndDate={ChartEndDate}
                        Goals={Chart.Goals}
                        Increment={Chart.Increment}
                        IsTime={IsTime}
                        Maximum={Chart.Maximum}
                        Minimum={Chart.Minimum}
                        Measurements={Chart.Measurements}
                        Measurements_Max={Chart.Measurements_Max}
                        Measurements_Min={Chart.Measurements_Min}
                        SpanGaps={true}
                        StartDate={ChartStartDate}
                        TimePeriod={StateTimePeriod}
                        ToolTipConvertXDate={ToolTipConvertXDate}
                        ToolTipConvertYToSeconds={ToolTipConvertYToSeconds}
                        Units=""
                    />
                </BodyGoalLineGraphContainer>
            </BodyGoalChartContainer>
        );
    }

    renderNoMeasurements = () => {
        var { t } = this.props;

        return (
            <NoMeasurementsContainer>
                <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="center" NoMargin Text={t('Progress_BodyGoal_NoMeasurements')} TextAlign="center" />

                <Spacer Size="medium" />

                <ButtonGroup Buttons={[{ BackgroundColor: 'blue-abel', BackgroundColorHover: 'blue-astronaut', Color: 'white', ColorHover: 'white', FontFamily: 'semibold', OnClick: () => this.onToggleShowAddMeasurementsModal({ ShowAddMeasurementsModal: true }), Title: t('track_progress') }]} />
            </NoMeasurementsContainer>
        );
    }

    renderShowChooseDatesModal = () => {
        var { ChartEndDate, ShowChooseDatesModal, ChartStartDate } = this.state;

        if (ShowChooseDatesModal) {
            return (
                <ChooseDates
                    IsDateRange
                    OnHide={() => this.onToggleShowChooseDatesModal(false)}
                    OnSelectDates={({ EndDate, StartDate }) => this.onSelectCustomDates({ EndDate, StartDate })}
                    RequiredDate
                    SelectedDates={[]}
                    StartDate={ChartEndDate}
                    EndDate={ChartStartDate}
                    Show={ShowChooseDatesModal}
                />
            );
        }
    }

    render() {
        if (this.state.Loading) return <Loading />;

        var { ClientDetails: { ClientId, DisplayName }, TryingGetGarminData } = this.props;
        var { HasData, Title } = this.state;

        return (
            <>
                {(TryingGetGarminData) && <Loading />}

                <Page
                    renderBreadcrumbs={[
                        { Name: `${DisplayName}`, LinkURL: `client/${ClientId}`, Props: { SelectedTab: 'Progress' }},
                        { Name: Title }
                    ]}
                >
                    <PageText FontFamily="medium" FontSize="large" NoMargin Text={Title} />

                    <Spacer Size="large" />
                    {
                        !!+HasData ?
                        <>
                            {this.renderChart()}
                        </>
                    :
                        this.renderNoMeasurements()
                    }
                </Page>

                {this.renderShowChooseDatesModal()}
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,

        UserDetails: state.Auth.UserDetails,

        ClientDetails: state.Client.ClientDetails,

        TryingGetGarminData: state.Progress.TryingGetGarminData,
        TryingGetGarminDataError: state.Progress.TryingGetGarminDataError
    };
};

export default withTranslation()(connect(mapStateToProps, { GetClientDetails, GetGarminData_BodyBattery, GetGarminData_Sleep, GetGarminData_Stress } )(GarminProgress));