import styled from 'styled-components';

import Icon from '../../Components/Icon';
import Input from '../../Components/Form/Input';

export const CompaniesBodyRow = styled.div`
    border-left: 2px solid var(--abel-blue);
    border-right: 2px solid var(--abel-blue);
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    width: 100%;

    @media(hover:hover) {
        :hover {
            background-color: var(--white-concrete);
            cursor: pointer;
        }
    }

    > div {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 10px;
    }

    :last-child {
        border-bottom: 2px solid var(--abel-blue);
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
`;

export const CompaniesColumnContainer = styled.div``;

export const CompaniesContainer = styled.div`
    border-radius: 10px;
    overflow: hidden;
`;

export const CompaniesHeaderRow = styled.div`
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    width: 100%;

    > div {
        background-color: var(--abel-blue);
        color: var(--white);
        padding: 10px;
    }
`;

export const EmployeesBodyRow = styled.div`
    border-left: 2px solid var(--abel-blue);
    border-right: 2px solid var(--abel-blue);
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;

    @media(hover:hover) {
        :hover {
            background-color: var(--white-concrete);
        }
    }

    > div {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 10px;
    }

    :last-child {
        border-bottom: 2px solid var(--abel-blue);
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
`;

export const EmployeesBodyRowPending = styled.div`
    border-left: 2px solid var(--abel-blue);
    border-right: 2px solid var(--abel-blue);
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;

    @media(hover:hover) {
        :hover {
            background-color: var(--white-concrete);
        }
    }

    > div {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 10px;
    }

    :last-child {
        border-bottom: 2px solid var(--abel-blue);
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
`;

export const EmployeesColumnContainer = styled.div``;

export const EmployeesContainer = styled.div`
    border-radius: 10px;
    overflow: hidden;
`;

export const EmployeesHeaderRow = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;

    > div {
        background-color: var(--abel-blue);
        color: var(--white);
        padding: 10px;
    }
`;

export const EmployeesHeaderRowPending = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;

    > div {
        background-color: var(--abel-blue);
        color: var(--white);
        padding: 10px;
    }
`;

export const EmployeesSectionContainer = styled.div``;

export const InputContainer = styled.div`
    margin: 0 auto;
    max-width: 500px;
    width: 100%;

    :not(:last-child) {
        margin-bottom: 15px;
    }
`;

export const InputInnerContainer = styled.div`
    align-items: center;
    display: grid;
    grid-template-columns: 9fr 1fr;
`;

export const InsertEditCompanyModalContainer = styled.div``;

export const InviteLinkContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    grid-gap: 10px;
`;

export const SearchAndAddContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    > div:first-child {
        flex: 1;
    }
`;

export const SearchCompaniesInput = styled(Input)`
    margin-right: 10px;

    > input {
        border-radius: 10px;
    }
`;

export const StyledCompanyName = styled(Input)`
    > div {
        font-family: var(--font-family-medium);
        font-size: var(--font-size-medium-3);
        margin-bottom: 20px;
        text-align: center;
    }

    > input {
        text-align: center;
        text-indent: 0px;
    }
`;

export const StyledDeleteIcon = styled(Icon)`
    height: 20px;
    margin-left: 10px;
    width: 20px;
`;

export const StyledInput = styled(Input)`
    background-color: var(--white-concrete);
    border: none; 
    border-radius: 10px;

    > input {
        background-color: var(--white-concrete);
    }
`;

export const ToggleContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;

    > div:first-child {
        margin-right: 10px;
    }
`;