import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Tasks_AssignStatus, Tasks_DeleteStatus, Tasks_GetGetCustomStatusDetails, Tasks_GetStatuses, Tasks_InsertEditStatus } from '../../../../Services/Actions';
import Popover from 'react-popover';

import * as styles from './styles';

import AssignClients from '../../../../Components/AssignClients';
import ButtonGroup from '../../../../Components/Buttons/ButtonGroup';
import { Icon as IconComponent, IconsListByName } from '../../../../Components/IconLibrary';
import * as IconsList from '../../../../Components/IconLibrary';
import Header from '../../Header';
import Loading from '../../../../Components/Loading';
import MultiText from '../../../../Components/Text/MultiText';
import PageText from '../../../../Components/Text/PageText';
import PaginationContainer from '../../../../Components/PaginationCounter';
import Spacer from '../../../../Components/Spacer';
import Toggle from '../../../../Components/Form/Toggle';
import TranslationsComponent from '../../../../Components/Translations';

import Info from '../../../../Modals/Info';

import { ReactComponent as AssignToSVG } from '../../../../Assets/SVG/AssignTo.svg';
import InfoIcon from '../../../../Assets/Icons/Info.png';
import Plus from '../../../../Assets/Icons/PlusSign_White.png';

const defaultAssignStatusInfo = {
    ByDay: [],
    EndDate: '',
    EndingType: null, // "EndDate" or "NumMaxOccurrences"
    Frequency: null,
    Interval: 0,
    NumMaxOccurrences: 0,
    SelectedUsers: [],
    SelectedUserIds: [],
    StartDate: '',
    Status: {}
}

const defaultStatusInfo = {
    Description: '',
    GoalAmount: '',
    Icon: '',
    ResponseHidden: 0,
    StatusId: 0,
    StatusName: '',
    Translations: { DefaultLanguageId: null, Languages: [], Translations: {} }
}

const WeeklyFrequencyDays = [
    { DayId: 'MO', DayName: 'monday_abbr' },
    { DayId: 'TU', DayName: 'tuesday_abbr' },
    { DayId: 'WE', DayName: 'wednesday_abbr' },
    { DayId: 'TH', DayName: 'thursday_abbr' },
    { DayId: 'FR', DayName: 'friday_abbr' },
    { DayId: 'SA', DayName: 'saturday_abbr' },
    { DayId: 'SU', DayName: 'sunday_abbr' }
]

const MAX_DESCRIPTION_LENGTH = 200;
const MAX_NAME_LENGTH = 30;

class StatusesComponent extends React.Component {
    TimeoutId = null;

    state = {
        StatusNameSearch_Previous: '',
        StatusNameSearch: '',
        Statuses: [],
        IsMoreResults: null,
        PageNo: 1,
        PageSize: 25,
        TotalPages: null,
        TotalRecords: null,

        PopoverIsOpen_Statuses: false,

        ShowAddStatusModal: false,
        ShowAddStatusModal_Info: { ...defaultStatusInfo },
        ShowAddStatusModel_GotDetails: false,

        ShowAddStatusIconsModal: false,

        ShowAssignStatus: false,
        ShowAssignStatus_Data: { ...defaultAssignStatusInfo },

        ShowDeleteStatusModal: false
    }

    componentDidMount() {
        this.onLoadStatuses();
    }

    onAssignStatus = () => {
        var { ByDay, EndDate, Frequency, Interval, NumMaxOccurrences, SelectedUserIds, StartDate, Status: { StatusId } } = this.state.ShowAssignStatus_Data;

        var ClientIds = SelectedUserIds.join(',');

        var RecurrencePattern = '';
        if (Frequency === 'Daily' || Frequency === 'Weekly' || Frequency === 'Monthly') {
            RecurrencePattern += `FREQ=${Frequency.toUpperCase()};`;
            if (Frequency === 'Weekly') RecurrencePattern += `BYDAY=${ByDay.join(',')};`;
            if (Frequency === 'Monthly') RecurrencePattern += `BYMONTHDAY=${this.state.ShowAssignStatus_Data.StartDate.slice(-2)};`;
            RecurrencePattern += `INTERVAL=${Interval};`;
            RecurrencePattern += `UNTIL=${EndDate}`;
        }

        this.props.Tasks_AssignStatus({ ClientIds, EndDate, NumMaxOccurrences, RecurrencePattern, StartDate, StatusIds: `${StatusId}` }).then(() => {
            this.onToggleShowAssignStatus({ ShowAssignStatus: false });
        })
    }

    onBlurSearchStatuses = () => {
        var { StatusNameSearch, StatusNameSearch_Previous } = this.state;

        if (StatusNameSearch !== StatusNameSearch_Previous) {
            this.setState({ StatusNameSearch_Previous: StatusNameSearch, PageNo: 1 }, () => this.onLoadStatuses());
        }
    }

    onChangeAssignStatus_ByDay = ({ DayId, SelectedIndex }) => {
        var ByDay = [ ...this.state.ShowAssignStatus_Data.ByDay ];

        if (SelectedIndex === -1) ByDay = [ ...ByDay, DayId ];
        else ByDay = [ ...ByDay.slice(0, SelectedIndex), ...ByDay.slice(SelectedIndex + 1) ];

        this.setState({ ShowAssignStatus_Data: { ...this.state.ShowAssignStatus_Data, ByDay }});
    }

    onChangeAssignStatus_Clients = ({ SelectedClients: SelectedUsers }) => {
        var SelectedUserIds_New = SelectedUsers.map(User => User.UserId);
        SelectedUsers = SelectedUsers.map(User => ({ ...User, Sex: User.Gender }));

        this.setState({ ShowAssignStatus_Data: { ...this.state.ShowAssignStatus_Data, SelectedUsers, SelectedUserIds: SelectedUserIds_New }});
    }

    onChangeAssignStatus_Frequency = Frequency => {
        this.setState({ ShowAssignStatus_Data: { ...this.state.ShowAssignStatus_Data, Frequency }});
    }

    onChangeAssignStatus_EndDate = event => {
        this.setState({ ShowAssignStatus_Data: { ...this.state.ShowAssignStatus_Data, EndDate: event.target.value }});
    }

    onChangeAssignStatus_EndingType = EndingType => {
        this.setState({ ShowAssignStatus_Data: { ...this.state.ShowAssignStatus_Data, EndingType }});
    }

    onChangeAssignStatus_NumMaxOccurrences = event => {
        var NumMaxOccurrences = +event.target.value;
        if (NumMaxOccurrences >= 100) NumMaxOccurrences = 100;

        this.setState({ ShowAssignStatus_Data: { ...this.state.ShowAssignStatus_Data, NumMaxOccurrences }});
    }

    onChangeAssignStatus_StartDate = event => {
        this.setState({ ShowAssignStatus_Data: { ...this.state.ShowAssignStatus_Data, StartDate: event.target.value }});
    }

    onChangeStatusIcon = Icon => {
        this.setState({ ShowAddStatusModal_Info: { ...this.state.ShowAddStatusModal_Info, Icon }}, () => {
            this.onToggleShowAddStatusIconsModal({ ShowAddStatusIconsModal: false });
        });
    }

    onChangeStatusesSearch = event => {
        this.setState({ StatusNameSearch: event.target.value });
    }

    onDeleteStatus = () => {
        var { StatusId } = this.state.ShowAddStatusModal_Info;

        this.onToggleShowDeleteStatusModal({ ShowDeleteStatusModal: false });
        this.onToggleShowAddStatusModal({ ShowAddStatusModal: false });

        this.props.Tasks_DeleteStatus({ StatusId }).then(() => {
            this.setState({ PageNo: 1, StatusNameSearch_Previous: '', StatusNameSearch: '' }, () => {
                this.onLoadStatuses();
            });
        })
    }

    onInsertEditStatus = () => {
        var { Icon, ResponseHidden, StatusId, Translations } = this.state.ShowAddStatusModal_Info;

        var NewTranslations = { DefaultLanguageId: Translations.DefaultLanguageId, Translations: [] };

        NewTranslations.Translations = Object.keys(Translations.Translations).map(key => {
            var { Description, StatusName } = Translations.Translations[key];

            return { LanguageId: key, StatusName: StatusName.substring(0, MAX_NAME_LENGTH), Description: Description.substring(0, MAX_DESCRIPTION_LENGTH) };
        });

        this.props.Tasks_InsertEditStatus({ Icon, ResponseHidden, StatusId, Translations: NewTranslations }).then(() => {
            this.onToggleShowAddStatusModal({ ShowAddStatusModal: false });
            this.setState({ PageNo: 1, StatusNameSearch_Previous: '', StatusNameSearch: '' }, () => {
                this.onLoadStatuses();
            });
        })
    }

    onLoadStatuses = Increment => {
        var { StatusNameSearch: StatusName, PageNo, PageSize  } = this.state;

        if (Increment) PageNo++;

        this.props.Tasks_GetStatuses({ PageNo, PageSize, StatusName }).then(({ Statuses, IsMoreResults, TotalRecords }) => {
            var TotalPages = Math.floor(TotalRecords / PageSize) + 1;
            this.setState({ Statuses, IsMoreResults, TotalPages, TotalRecords });
        });
    }

    onPaginate = ({ NewPageNo }) => {
        if (this.statePageNo !== NewPageNo) this.setState({ PageNo: NewPageNo }, () => this.onLoadStatuses());
    }

    onTogglePopover = PopoverType => {
        this.setState({ [PopoverType]: !this.state[PopoverType] }, () => {
            if (!this.state[PopoverType] && !!this.TimeoutId) clearTimeout(this.TimeoutId);
        });
    }

    onToggleResponseHidden = event => {
        this.setState({ ShowAddStatusModal_Info: { ...this.state.ShowAddStatusModal_Info, ResponseHidden: event.target.checked }});
    }

    onToggleShowAddStatusModal = ({ ShowAddStatusModal, Status = {} }) => {
        if (!ShowAddStatusModal) this.setState({ ShowAddStatusModal, ShowAddStatusModal_Info: { ...defaultStatusInfo } });
        else {
            var LanguageId = this.props.UserDetails.LanguageId;
            var { StatusId } = Status;

            this.props.Tasks_GetGetCustomStatusDetails({ LanguageId, StatusId }).then(({ Status, Languages, Translations }) => {
                this.setState({ ShowAddStatusModal, ShowAddStatusModal_Info: { ...defaultStatusInfo, ...Status, Translations: { ...Translations, Languages } } })
            })
        };
    }

    onToggleShowAddStatusIconsModal = ({ ShowAddStatusIconsModal }) => {
        this.setState({ ShowAddStatusIconsModal });
    }

    onToggleShowAssignStatus = ({ event, ShowAssignStatus, Status = {} }) => {
        if (event) event.stopPropagation();

        if (!ShowAssignStatus) this.setState({ ShowAssignStatus, ShowAssignStatus_Data: { ...defaultAssignStatusInfo } });
        else this.setState({ ShowAssignStatus, ShowAssignStatus_Data: { ...defaultAssignStatusInfo, Status } })
    }

    onToggleShowDeleteStatusModal = ({ ShowDeleteStatusModal }) => {
        this.setState({ ShowDeleteStatusModal });
    }

    onTranslations_AddLanguage = () => {
        var NewShowAddStatusModal_Info = { ...this.state.ShowAddStatusModal_Info };
        var NewTranslations = { ...NewShowAddStatusModal_Info.Translations };

        var FirstNonUsedLanguage = NewTranslations.Languages.filter(({ LanguageId }) => !NewTranslations.Translations.hasOwnProperty(`${LanguageId}`))[0].LanguageId;

        NewTranslations.Translations = { ...NewTranslations.Translations, [`${FirstNonUsedLanguage}`]: { Description: '', StatusName: '', LanguageId: +FirstNonUsedLanguage } };

        NewShowAddStatusModal_Info.Translations = NewTranslations;

        this.setState({ ShowAddStatusModal_Info: NewShowAddStatusModal_Info });
    }

    onTranslations_ChangeDefault = LanguageId => {
        var NewShowAddStatusModal_Info = { ...this.state.ShowAddStatusModal_Info };
        var NewTranslations = { ...NewShowAddStatusModal_Info.Translations, DefaultLanguageId: LanguageId };

        NewShowAddStatusModal_Info.Translations = NewTranslations;

        this.setState({ ShowAddStatusModal_Info: NewShowAddStatusModal_Info });
    }

    onTranslations_ChangeLanguage = ({ NewLanguage, TranslationKey }) => {
        if (+NewLanguage.LanguageId !== +TranslationKey) {
            var NewShowAddStatusModal_Info = { ...this.state.ShowAddStatusModal_Info };
            var NewTranslations = { ...NewShowAddStatusModal_Info.Translations };

            NewTranslations.Translations = { ...NewTranslations.Translations, [`${NewLanguage.LanguageId}`]: { ...NewTranslations.Translations[`${TranslationKey}`] } };
            delete NewTranslations.Translations[`${TranslationKey}`];

            // If DefaultLanguageId Is The Language That Changes, Then Set DefaultLanguageId to NewLanguage
                if (+NewTranslations.DefaultLanguageId === +TranslationKey) NewTranslations = { ...NewTranslations, DefaultLanguageId: NewLanguage.LanguageId };

            NewShowAddStatusModal_Info.Translations = NewTranslations;

            this.setState({ ShowAddStatusModal_Info: NewShowAddStatusModal_Info });
        }
    }

    onTranslations_ChangeTranslation = ({ event, InputName, LanguageId }) => {
        var NewShowAddStatusModal_Info = { ...this.state.ShowAddStatusModal_Info };
        var NewTranslations = { ...NewShowAddStatusModal_Info.Translations };
        NewTranslations.Translations = { ...NewTranslations.Translations, [`${LanguageId}`]: { ...NewTranslations.Translations[`${LanguageId}`], [InputName]: event.target.value } };

        NewShowAddStatusModal_Info.Translations = NewTranslations;

        this.setState({ ShowAddStatusModal_Info: NewShowAddStatusModal_Info });
    }

    onTranslations_DeleteLanguage = LanguageId => {
        var NewShowAddStatusModal_Info = { ...this.state.ShowAddStatusModal_Info };
        var NewTranslations = { ...NewShowAddStatusModal_Info.Translations };
        NewTranslations.Translations = { ...NewTranslations.Translations };
        delete NewTranslations.Translations[`${LanguageId}`];

        NewShowAddStatusModal_Info.Translations = NewTranslations;

        this.setState({ ShowAddStatusModal_Info: NewShowAddStatusModal_Info });
    }

    renderAddStatusIconsModal = () => {
        var { ShowAddStatusIconsModal } = this.state;

        if (ShowAddStatusIconsModal) {
            return (
                <Info
                    InformationRenderer={this.renderAddStatusIconsModal_Information}
                    OnHide={() => this.onToggleShowAddStatusIconsModal({ ShowAddStatusIconsModal: false })}
                    Show={ShowAddStatusIconsModal}
                />
            );
        }
    }

    renderAddStatusIconsModal_Information = () => {
        var { t } = this.props;
        var { Icon: SelectedIcon } = this.state.ShowAddStatusModal_Info;

        return (
            <>
                <PageText FontFamily="medium" FontSize="medium-3" JustifyContent="center" NoMargin Text={t('pick_icon')} TextAlign="center" />

                <Spacer Size="small" />

                <styles.IconsListContainer className="IconsListContainer">
                    {
                        IconsListByName.map(IconName => {
                            var Id = IconName;

                            return (
                                <styles.IconContainer className="IconContainer" key={Id} Selected={Id === SelectedIcon} onClick={() => this.onChangeStatusIcon(Id)}>
                                    <IconComponent src={IconsList[IconName]} />
                                </styles.IconContainer>
                            );
                        })
                    }
                </styles.IconsListContainer>
            </>
        );

    }

    renderAddStatusModal_Information = () => {
        var { t } = this.props;
        var UserDetails = this.props.UserDetails;
        var { Icon, ResponseHidden, StatusId, Translations } = this.state.ShowAddStatusModal_Info;

        var Disabled = !Icon || !Translations.Translations[`${Translations.DefaultLanguageId}`].StatusName;

        var Buttons = [];
        Buttons.push({ BackgroundColor: 'white-concrete-hover', BackgroundColorHover: 'gray-dark', Border: 'none', Color: 'white', ColorHover: 'white', FontFamily: 'semibold', OnClick: () => this.onToggleShowAddStatusModal({ ShowAddStatusModal: false }), Title: t('_cancel') });
        Buttons.push({ BackgroundColor: 'blue-abel', BackgroundColorHover: 'blue-abel-hover', Border: 'none', Color: 'white', ColorHover: 'white', Disabled, FontFamily: 'semibold', OnClick: () => this.onInsertEditStatus(), Title: t('_save') });

        if (+StatusId) Buttons.push({ BackgroundColor: 'red-bittersweet', BackgroundColorHover: 'red-bittersweet-hover', Border: 'none', Color: 'white', ColorHover: 'white', FontFamily: 'semibold', OnClick: () => this.onToggleShowDeleteStatusModal({ ShowDeleteStatusModal: true }), Title: t('delete_with_first_char_uppercase') });

        return (
            <>
                <styles.BodyHeader className="BodyHeader">
                    <PageText FontFamily="medium" FontSize="large" JustifyContent="flex-start" NoMargin Text={+StatusId ? t('edit_status') : t('create_status')} TextAlign="left" />
                </styles.BodyHeader>

                <Spacer Size="small" />

                <styles.InsertEditModalContainer className="InsertEditModalContainer">
                    {/* Translation Component */}
                        <TranslationsComponent
                            ContentType="Status"
                            DefaultLanguageId={Translations.DefaultLanguageId || UserDetails.LanguageId}
                            Languages={Translations.Languages}
                            OnAddLanguage={this.onTranslations_AddLanguage}
                            OnChangeDefault={this.onTranslations_ChangeDefault}
                            OnChangeLanguage={this.onTranslations_ChangeLanguage}
                            OnChangeTranslation={this.onTranslations_ChangeTranslation}
                            OnDeleteLanguage={this.onTranslations_DeleteLanguage}
                            Translations={Translations.Translations}
                        />

                    <Spacer Size="small" />

                    {/* Response Hidden */}
                        <MultiText
                            TextAlign="left"
                            Texts={[
                                { FontFamily: 'medium', FontSize: 'medium-2', Text: t('status_hide_response_title') },
                                { FontColor: 'red-bittersweet', FontFamily: 'medium', FontSize: 'medium-2', Text: '*' },
                            ]}
                        />

                        <Spacer Size="small" />

                        <Toggle
                            Checked={!!ResponseHidden}
                            OffLabel={t('_no')}
                            OnChange={this.onToggleResponseHidden}
                            OnLabel={t('_yes')}
                        />

                    {/* Icon */}
                        <MultiText
                            TextAlign="center"
                            Texts={[
                                { FontFamily: 'medium', FontSize: 'medium-2', Text: t('status_icon') },
                                { FontColor: 'red-bittersweet', FontFamily: 'medium', FontSize: 'medium-2', Text: '*' },
                            ]}
                        />

                        <Spacer Size="small" />

                        {
                            !!Icon &&
                            <styles.SelectedIconContainer className="SelectedIconContainer">
                                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('WorkoutPlanSettings_selected')} TextAlign="left" />

                                <IconComponent src={IconsList[Icon]} />
                                
                                <Spacer Size="small" />
                            </styles.SelectedIconContainer>
                        }

                        <ButtonGroup
                            Buttons={[{ BackgroundColor: 'blue-abel', BackgroundColorHover: 'blue-abel-hover', Border: 'none', Color: 'white', ColorHover: 'white', FontFamily: 'semibold', OnClick: () => this.onToggleShowAddStatusIconsModal({ ShowAddStatusIconsModal: true }), Title: t('view_icons_list') }]}
                            ButtonWidth="fit-content"
                            NotTouching
                            OwnRows={false}
                        />

                    <Spacer Size="medium" />

                    <ButtonGroup
                        Buttons={Buttons}
                        ButtonWidth="fit-content"
                        NotTouching
                        OwnRows={false}
                    />
                </styles.InsertEditModalContainer>
            </>
        );
    }

    renderAssignStatus = () => {
        var { t } = this.props;
        var { ByDay, EndDate, EndingType, Frequency, NumMaxOccurrences, SelectedUsers, SelectedUserIds, StartDate, Status } = this.state.ShowAssignStatus_Data;

        var Today = new Date();

        var MinDate = Today.toJSON().slice(0,10);
        var MaxDate = new Date(Today.setFullYear(Today.getFullYear() + 1)).toJSON().slice(0,10);
        var MinDate_EndDate = StartDate;

        var Disabled = false;

        if (!StartDate || SelectedUserIds.length === 0 || !Frequency || (Frequency === 'Weekly' && ByDay.length === 0)) Disabled = true;
        else if (((Frequency === 'Daily' || Frequency === 'Weekly' || Frequency === 'Monthly') && !EndingType) || ((Frequency === 'Daily' || Frequency === 'Weekly' || Frequency === 'Monthly') && EndingType === 'EndDate' && !EndDate) || ((Frequency === 'Daily' || Frequency === 'Weekly' || Frequency === 'Monthly') && EndingType === 'NumMaxOccurrences' && !NumMaxOccurrences)) Disabled = true;

        var Buttons = [];
        Buttons.push({ BackgroundColor: 'white-concrete-hover', BackgroundColorHover: 'gray-dark', Border: 'none', Color: 'white', ColorHover: 'white', FontFamily: 'semibold', OnClick: () => this.onToggleShowAssignStatus({ ShowAssignStatus: false }), Title: t('_cancel') });
        Buttons.push({ BackgroundColor: 'blue-abel', BackgroundColorHover: 'blue-abel-hover', Border: 'none', Color: 'white', ColorHover: 'white', Disabled, FontFamily: 'semibold', OnClick: () => this.onAssignStatus(), Title: t('_save') });

        return (
            <styles.AssignTaskContainer className="AssignTaskContainer">
                <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="flex-start" NoMargin Text={t('assign_to_clients')} TextAlign="left" />

                <Spacer Size="small" />

                <styles.TasksContainer className="TasksContainer">
                    {this.renderStatus({ ...Status, ReadOnly: true })}
                </styles.TasksContainer>

                <Spacer Size="small" />

                {/* Start Date */}
                    <MultiText
                        TextAlign="left"
                        Texts={[
                            { FontFamily: 'medium', FontSize: 'medium-2', Text: t('start_date') },
                            { FontColor: 'red-bittersweet', FontFamily: 'medium', FontSize: 'medium-2', Text: '*' },
                        ]}
                    />

                    <styles.StyledAccountInput
                        FontSize="medium-1"
                        Min={MinDate}
                        OnChange={this.onChangeAssignStatus_StartDate}
                        Size="large"
                        Type="date"
                        Value={StartDate}
                    />

                <Spacer Size="small" />

                {/* Repeating */}
                    {/* Frequency */}
                        <MultiText
                            TextAlign="left"
                            Texts={[
                                { FontFamily: 'medium', FontSize: 'medium-2', Text: t('statuses_want_to_repeat') },
                                { FontColor: 'red-bittersweet', FontFamily: 'medium', FontSize: 'medium-2', Text: '*' },
                            ]}
                        />

                        <styles.RadioButtonParentContainer className="RadioButtonParentContainer">
                            <styles.PseudoCheckbox className="PseudoCheckbox" BackgroundColor="274775" onClick={() => this.onChangeAssignStatus_Frequency('Daily')} Selected={Frequency === 'Daily'} />

                            <styles.RadioButtonPageText ElementType="span" FontFamily={Frequency === 'Daily' ? 'semibold' : 'medium'} FontSize="medium-1" JustifyContent="flex-start" OnClick={() => this.onChangeAssignStatus_Frequency('Daily')} Text={t('daily')} TextAlign="left" />
                        </styles.RadioButtonParentContainer>

                        <styles.RadioButtonParentContainer className="RadioButtonParentContainer">
                            <styles.PseudoCheckbox className="PseudoCheckbox" BackgroundColor="274775" onClick={() => this.onChangeAssignStatus_Frequency('Weekly')} Selected={Frequency === 'Weekly'} />

                            <styles.RadioButtonPageText ElementType="span" FontFamily={Frequency === 'Weekly' ? 'semibold' : 'medium'} FontSize="medium-1" JustifyContent="flex-start" OnClick={() => this.onChangeAssignStatus_Frequency('Weekly')} Text={t('weekly')} TextAlign="left" />
                        </styles.RadioButtonParentContainer>

                        {
                            Frequency === 'Weekly' &&
                            <>
                                <Spacer Size="extra-extra-small" />

                                <styles.WeeklyFrequencyDaysContainer className="WeeklyFrequencyDaysContainer">
                                    {
                                        WeeklyFrequencyDays.map(({ DayId, DayName }) => {
                                            var SelectedIndex = ByDay.indexOf(DayId);
                                            var Selected = SelectedIndex !== -1;

                                            return (
                                                <styles.WeeklyFrequencyDayContainer className="WeeklyFrequencyDayContainer" key={DayId}>
                                                    <PageText FontColor={Selected ? 'blue-abel' : 'black'} FontFamily={Selected ? 'semibold' : 'medium'} FontSize="medium-1" JustifyContent="flex-start" NoMargin OnClick={() => this.onChangeAssignStatus_ByDay({ DayId, SelectedIndex })} Text={t(DayName)} TextAlign="left" />
                                                </styles.WeeklyFrequencyDayContainer>
                                            );
                                        })
                                    }
                                </styles.WeeklyFrequencyDaysContainer>
                                
                                <Spacer Size="extra-extra-small" />
                            </>
                        }

                        <styles.RadioButtonParentContainer className="RadioButtonParentContainer">
                            <styles.PseudoCheckbox className="PseudoCheckbox" BackgroundColor="274775" onClick={() => this.onChangeAssignStatus_Frequency('Monthly')} Selected={Frequency === 'Monthly'} />

                            <styles.RadioButtonPageText ElementType="span" FontFamily={Frequency === 'Monthly' ? 'semibold' : 'medium'} FontSize="medium-1" JustifyContent="flex-start" OnClick={() => this.onChangeAssignStatus_Frequency('Monthly')} Text={t('monthly')} TextAlign="left" />
                        </styles.RadioButtonParentContainer>

                        {
                            Frequency === 'Monthly' &&
                            <>
                                <Spacer Size="extra-extra-small" />

                                <PageText FontFamily="medium-italic" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('_monthly_date_is_determined_by_startdate')} TextAlign="left" />

                                <Spacer Size="extra-extra-small" />
                            </>
                        }

                        <styles.RadioButtonParentContainer className="RadioButtonParentContainer">
                            <styles.PseudoCheckbox className="PseudoCheckbox" BackgroundColor="274775" onClick={() => this.onChangeAssignStatus_Frequency('None')} Selected={Frequency === 'None'} />

                            <styles.RadioButtonPageText ElementType="span" FontFamily={Frequency === 'None' ? 'semibold' : 'medium'} FontSize="medium-1" JustifyContent="flex-start" OnClick={() => this.onChangeAssignStatus_Frequency('None')} Text={t('no_repeat')} TextAlign="left" />
                        </styles.RadioButtonParentContainer>

                {/* Ending Type */}
                    {
                        (Frequency === 'Daily' || Frequency === 'Weekly' || Frequency === 'Monthly') &&
                        <>
                            <Spacer Size="medium" />

                            <MultiText
                                TextAlign="left"
                                Texts={[
                                    { FontFamily: 'medium', FontSize: 'medium-2', Text: t('taskstatus_repeat_endingtype') },
                                    { FontColor: 'red-bittersweet', FontFamily: 'medium', FontSize: 'medium-2', Text: '*' },
                                ]}
                            />

                            <styles.RadioButtonParentContainer className="RadioButtonParentContainer">
                                <styles.PseudoCheckbox className="PseudoCheckbox" BackgroundColor="274775" onClick={() => this.onChangeAssignStatus_EndingType('EndDate')} Selected={EndingType === 'EndDate'} />

                                <styles.RadioButtonPageText ElementType="span" FontFamily={EndingType === 'EndDate' ? 'semibold' : 'medium'} FontSize="medium-1" JustifyContent="flex-start" OnClick={() => this.onChangeAssignStatus_EndingType('EndDate')} Text={t('end_date')} TextAlign="left" />
                            </styles.RadioButtonParentContainer>

                            {/* End Date */}
                                {
                                    EndingType === 'EndDate' &&
                                    <styles.StyledAccountInput
                                        Disabled={!StartDate}
                                        FontSize="medium-1"
                                        Max={MaxDate}
                                        Min={MinDate_EndDate}
                                        OnChange={this.onChangeAssignStatus_EndDate}
                                        Size="large"
                                        Type="date"
                                        Value={EndDate}
                                    />
                                }

                            <styles.RadioButtonParentContainer className="RadioButtonParentContainer">
                                <styles.PseudoCheckbox className="PseudoCheckbox" BackgroundColor="274775" onClick={() => this.onChangeAssignStatus_EndingType('NumMaxOccurrences')} Selected={EndingType === 'NumMaxOccurrences'} />

                                <styles.RadioButtonPageText ElementType="span" FontFamily={EndingType === 'NumMaxOccurrences' ? 'semibold' : 'medium'} FontSize="medium-1" JustifyContent="flex-start" OnClick={() => this.onChangeAssignStatus_EndingType('NumMaxOccurrences')} Text={t('num_max_occurrences')} TextAlign="left" />
                            </styles.RadioButtonParentContainer>

                            {/* Num Max Occurrences */}
                                {
                                    EndingType === 'NumMaxOccurrences' &&
                                    <styles.StyledAccountInput
                                        FontSize="medium-1"
                                        Max={100}
                                        Min={1}
                                        OnChange={this.onChangeAssignStatus_NumMaxOccurrences}
                                        Size="large"
                                        Type="number"
                                        Value={NumMaxOccurrences}
                                    />
                                }
                        </>
                    }

                    {/* Clients */}
                        <AssignClients
                            HideTitle
                            HorizontalScroll={false}
                            OnSelectClients={Users => this.onChangeAssignStatus_Clients(Users)}
                            SelectedUserIds={SelectedUserIds}
                            SelectedUsers={SelectedUsers}
                        />

                {/* Save Button */}
                    <Spacer Size="small" />

                    <ButtonGroup
                        Buttons={Buttons}
                        ButtonWidth="fit-content"
                        NotTouching
                        OwnRows={false}
                    />
            </styles.AssignTaskContainer>
        );
    }

    renderBody = () => {
        var { ShowAddStatusModal, ShowAssignStatus } = this.state;

        return (
            <styles.BodyContainer className="BodyContainer">
                {
                    ShowAddStatusModal ?
                    this.renderAddStatusModal_Information()
                :
                    ShowAssignStatus ?
                    this.renderAssignStatus()
                :
                    this.renderStatuses()
                }
            </styles.BodyContainer>
        );
    }
    
    renderStatuses = () => {
        var { t } = this.props;
        var { UserId } = this.props.UserDetails;
        var { Statuses, PageNo, PageSize, TotalPages, TotalRecords } = this.state;

        return (
            <>
                <styles.BodyHeader className="BodyHeader">
                    <PageText FontFamily="medium" FontSize="large" JustifyContent="flex-start" NoMargin Text={t('statuses')} TextAlign="left" />

                    {
                        (+UserId === 16 || +UserId === 1) &&
                        <styles.BodyHeaderRight className="BodyHeaderRight">
                            <ButtonGroup
                                Buttons={[{ BackgroundGradient: "gradient1", Border: 'none', Color: 'white', ColorHover: 'white', Icon: Plus, IconPosition: 'left', IconSize: { Height: '20px', Width: '20px' }, OnClick: () => this.onToggleShowAddStatusModal({ ShowAddStatusModal: true }), Title: t('new_status') }]}
                                ButtonWidth="fit-content"
                                ContainerWidth="fit-content"
                                JustifyContent="center"
                            />
                        </styles.BodyHeaderRight>
                    }
                </styles.BodyHeader>

                <Spacer Size="small" />

                {this.renderViewCount()}

                {
                    TotalRecords > 0 &&
                    <>
                        <Spacer Size="small" />

                        {
                            (PageNo !== 1 || PageSize < TotalRecords) &&
                            <>
                                <PaginationContainer OnPaginate={this.onPaginate} PageNo={PageNo} PageSize={PageSize} TotalPages={TotalPages} />
        
                                <Spacer Size="medium" />
                            </>
                        }

                        <styles.TasksContainer className="TasksContainer">
                            {Statuses.map(Status => this.renderStatus(Status))}
                        </styles.TasksContainer>
                    </>
                }
            </>
        );
    }

    renderFilters = () => {
        var { t } = this.props;

        const popoverProps_Statuses = {
            isOpen: this.state.PopoverIsOpen_Statuses,
            preferPlace: 'below',
            onOuterAction: () => this.onTogglePopover('PopoverIsOpen_Statuses'),
            body: this.renderPopoverContent('Statuses'),
            tipSize: 0.01
        }

        var { StatusNameSearch } = this.state;

        return (
            <styles.FiltersContainer className="FiltersContainer">
                <PageText FontFamily="bold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('MealPlan_Filters')} TextAlign="left" />

                <Spacer Size="extra-small" />

                <styles.FiltersSubContainer className="FiltersSubContainer">
                    <styles.FilterContainer className="FilterContainer">
                        <styles.FilterHeaderContainer className="FilterHeaderContainer">
                            <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin TextAlign="left" Text={t('_search')} />

                            <Popover {...popoverProps_Statuses}>
                                <styles.MaxPercentInfoIcon ImageSrc={InfoIcon} OnMouseEnter={() => this.onTogglePopover('PopoverIsOpen_Statuses')} OnMouseLeave={() => this.onTogglePopover('PopoverIsOpen_Statuses')} />
                            </Popover>
                        </styles.FilterHeaderContainer>

                        <Spacer Size="extra-extra-small" />

                        <styles.SearchUsersInput
                            FontSize="medium-1"
                            NoLabel
                            NoMargin
                            OnBlur={this.onBlurSearchStatuses}
                            OnChange={this.onChangeStatusesSearch}
                            Placeholder={t('_search')}
                            Size="medium"
                            Type="text"
                            Value={StatusNameSearch}
                        />
                    </styles.FilterContainer>
                </styles.FiltersSubContainer>
            </styles.FiltersContainer>
        );
    }

    renderPopoverContent = ContentType => {
        var { t } = this.props;

        return (
            <styles.PopoverContent className="PopoverContent">
                {ContentType === 'Statuses' && <PageText FontFamily="medium-italic" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('statuses_search_by_name_description')} TextAlign="center" />}
            </styles.PopoverContent>
        );
    }

    renderShowDeleteStatusModal = () => {
        var { t } = this.props;
        var { ShowDeleteStatusModal } = this.state;

        if (ShowDeleteStatusModal) {
            return (
                <Info
                    BottomButton="submit"
                    BottomButtonOnClick={this.onDeleteStatus}
                    BottomButtonText={t('delete_with_first_char_uppercase')}
                    Information={t('statuses_delete_warning')}
                    OnHide={() => this.onToggleShowDeleteStatusModal({ ShowDeleteStatusModal: false })}
                    Show={ShowDeleteStatusModal}
                    Size="small"
                />
            );
        }
    }

    renderStatus = Status => {
        var { UserId } = this.props.UserDetails;
        var { Icon, NotSelectable, ReadOnly, StatusId, StatusName } = Status;
        var IsOwner = (+UserId === 16 || +UserId === 1);

        if (!!NotSelectable) ReadOnly = 1;

        return (
            <styles.TaskContainer className="TaskContainer" key={StatusId} onClick={(ReadOnly || !IsOwner) ? () => null : () => this.onToggleShowAddStatusModal({ ShowAddStatusModal: true, Status })} ReadOnly={(ReadOnly || !IsOwner)}>
                <styles.TaskContainerLeft className="TaskContainerLeft">
                    <IconComponent src={IconsList[Icon]} />
                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={StatusName} TextAlign="left" />
                </styles.TaskContainerLeft>

                {
                    !ReadOnly &&
                    <ButtonGroup
                        Buttons={[{ BackgroundColorHover: 'gray-dark', Border: 'none', Color: 'white', ColorHover: 'white', IconSVG: AssignToSVG, IconPosition: 'top', IconOnly: true, IconSize: { Height: '20px', Width: '20px' }, OnClick: event => this.onToggleShowAssignStatus({ event, ShowAssignStatus: true, Status }) }]}
                        ButtonWidth="fit-content"
                        ContainerWidth="fit-content"
                        JustifyContent="center"
                    />
                }
            </styles.TaskContainer>
        );
    }

    renderViewCount = () => {
        var { t } = this.props;

        var { PageNo, PageSize, TotalRecords } = this.state;

        if (!TotalRecords) TotalRecords = 0;

        return (
            <div className="ViewCount">
                <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" Text={t('items_count_viewing')} />
                &nbsp;
                <PageText ElementType="span" FontFamily="semibold" FontSize="medium-1" Text={`${!TotalRecords ? 0 : ((PageNo - 1) * PageSize) + 1} - ${(PageNo * PageSize) < TotalRecords ? (PageNo * PageSize) : TotalRecords}`} />
                &nbsp;
                <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" Text={t('of')} />
                &nbsp;
                <PageText ElementType="span" FontFamily="semibold" FontSize="medium-1" Text={`${TotalRecords} ${t('statuses')}`} />
            </div>
        )
    }

    render() {
        return (
            <>
                {this.props.TryingTasksAction && <Loading />}

                {<Header HideTrashLink LibraryType="Statuses" ShowTrash={false} />}

                {this.renderFilters()}
                {this.renderBody()}

                {this.renderAddStatusIconsModal()}
                {this.renderShowDeleteStatusModal()}
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,
        UserDetails: state.Auth.UserDetails,

        TryingTasksAction: state.Tasks.TryingTasksAction,
        TryingTasksActionError: state.Tasks.TryingTasksActionError
    };
};

export default withTranslation()(connect(mapStateToProps, { Tasks_AssignStatus, Tasks_DeleteStatus, Tasks_GetGetCustomStatusDetails, Tasks_GetStatuses, Tasks_InsertEditStatus } )(StatusesComponent));