import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { AddCompanyEmployees, DeleteCompanyEmployees, DownloadCompanyEmployees, GetCompanies, GetCompany, InsertEditCompany, UpdateCompanyEmployees } from '../../Services/Actions';
import Select from 'react-select';

import { COMPANY_INVITE_URL } from '../../Config';

import * as Styles from './styles';

import { toast } from 'react-toastify';

import ButtonGroup from '../../Components/Buttons/ButtonGroup';
import Loading from '../../Components/Loading';
import MultiText from '../../Components/Text/MultiText';
import Page from '../../Components/Page';
import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';
import Toggle from '../../Components/Form/Toggle';

import { emailValidator } from '../../Functions';

import Info from '../../Modals/Info';

import Copy from '../../Assets/Icons/Copy_White.png';
import DownloadIcon from '../../Assets/Icons/Download_Gray.png';
import EditIcon from '../../Assets/Icons/Swap_White.png';
import Plus from '../../Assets/Icons/PlusSign_White.png';
import RedX from '../../Assets/Icons/X_Red.png';

const DropdownStyles = {
    control: (styles, { isDisabled }) => ({ ...styles, backgroundColor: 'var(--white-concrete)', border: 'none', cursor: isDisabled ? 'not-allowed' : 'default', height: '45px', maxWidth: '100%', width: '200px' }),
    input: styles => ({ ...styles, maxWidth: '100%', width: '200px' }),
    menu: styles => ({ ...styles, maxWidth: '100%', width: '200px' }),
    option: (styles, { isDisabled }) => ({ ...styles, cursor: isDisabled ? 'not-allowed' : 'default', maxWidth: '100%', width: '200px' })
};

class CompaniesComponent extends React.Component {
    _isMounted = false;

    state = {
        Companies: [],
        InsertEditCompany: {},
        ShowAddEmployeesModal: false,
        ShowAddEmployeesModal_CompanyId: null,
        ShowAddEmployeesModal_CompanyName: '',
        ShowAddEmployeesModal_Emails: [],
        ShowAddEmployeesModal_TrainerEmail: '',
        ShowInsertEditCompanyModal: false,
        ShowInsertEditCompanyModal_EmployeeType: 'Active',
        SearchCompanyName: ''
    }

    componentDidMount() {
        this._isMounted = true;

        this.onGetCompanies();
    }

    onAddCompanyEmployees = () => {
        var { ShowAddEmployeesModal_CompanyId: CompanyId, ShowAddEmployeesModal_Emails: Emails, ShowAddEmployeesModal_TrainerEmail: TrainerEmail } = this.state;

        Emails = Emails.filter(({ Valid }) => !!Valid).map(({ Email }) => Email).join(';');

        if (Emails.length) {
            this.props.AddCompanyEmployees({ CompanyId, Emails, TrainerEmail }).then(({ Company }) => {
                if (Company) {
                    this.onToggleShowAddEmployeesModal({ ShowAddEmployeesModal: false });
                    if (+CompanyId === +this.state.InsertEditCompany.CompanyId) this.setState({ InsertEditCompany: Company });
                }
            })
        }
    }

    onAddEmail = () => {
        this.setState({ ShowAddEmployeesModal_Emails: [ ...this.state.ShowAddEmployeesModal_Emails, { Email: '', Valid: false } ] });
    }

    onBlurEmail = EmailIndex => {
        var ShowAddEmployeesModal_Emails = [ ...this.state.ShowAddEmployeesModal_Emails ];
        ShowAddEmployeesModal_Emails[EmailIndex].Valid = !!emailValidator(ShowAddEmployeesModal_Emails[EmailIndex].Email);

        this.setState({ ShowAddEmployeesModal_Emails });
    }

    onChangeAddEmployees_Company = ({ CompanyId, CompanyName }) => {
        this.setState({ ShowAddEmployeesModal_CompanyId: CompanyId, ShowAddEmployeesModal_CompanyName: CompanyName });
    }

    onChangeCompanyName = event => {
        var InsertEditCompany = { ...this.state.InsertEditCompany };
        InsertEditCompany.CompanyName = event.target.value;

        this.setState({ InsertEditCompany });
    }

    onChangeEmail = ({ event, EmailIndex }) => {
        var ShowAddEmployeesModal_Emails = [ ...this.state.ShowAddEmployeesModal_Emails ];
        ShowAddEmployeesModal_Emails[EmailIndex] = { Email: event.target.value, Valid: true };

        this.setState({ ShowAddEmployeesModal_Emails });
    }

    onChangeSearchCompanyName = event => {
        this.setState({ SearchCompanyName: event.target.value });
    }

    onChangeTrainerEmail = event => {
        this.setState({ ShowAddEmployeesModal_TrainerEmail: event.target.value });
    }

    onCopyInviteLink = InviteLinkUrl => {
        navigator.clipboard.writeText(InviteLinkUrl);

        toast.success(this.props.t('copied'), {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    onDeleteEmail = EmailIndex => {
        this.setState({ ShowAddEmployeesModal_Emails: [ ...this.state.ShowAddEmployeesModal_Emails.slice(0, EmailIndex), ...this.state.ShowAddEmployeesModal_Emails.slice(EmailIndex + 1) ] });
    }

    onDownloadEmployees = ({ CompanyId = 0, EmployeeType }) => {
        this.props.DownloadCompanyEmployees({ CompanyId, EmployeeType });
    }

    onGetCompanies = () => {
        var CompanyName = this.state.SearchCompanyName;

        this.props.GetCompanies({ CompanyName }).then(({ Companies }) => {
            this.setState({ Companies });
        });
    }

    onInsertEditCompany = (ResetLink = 0) => {
        var { CompanyId, CompanyName, HasWhitelabeling, OriginalName } = this.state.InsertEditCompany;

        this.props.InsertEditCompany({ CompanyId, CompanyName: !!ResetLink ? OriginalName : CompanyName, HasWhitelabeling, ResetLink }).then(({ InviteLink }) => {
            if (ResetLink) {
                var InsertEditCompany = { ...this.state.InsertEditCompany, OriginalName: CompanyName, InviteLink };
                this.setState({ InsertEditCompany });
            } else {
                this.onToggleShowInsertEditCompanyModal({ ShowInsertEditCompanyModal: false });
                this.onGetCompanies();
            }
        });
    }

    onRemoveCompanyEmployee = ({ CompanyId, UserId }) => {
        this.props.DeleteCompanyEmployees({ CompanyId, UserIds: `${UserId}`}).then(({ Company }) => {
            this.setState({ InsertEditCompany: { ...this.state.InsertEditCompany, ...Company } });
        });
    }

    onResetInviteLink = () => {
        this.onInsertEditCompany(1)
    }

    onToggleEmployeeType = ShowInsertEditCompanyModal_EmployeeType => {
        this.setState({ ShowInsertEditCompanyModal_EmployeeType });
    }

    onToggleHasWhitelabeling = event => {
        var InsertEditCompany = { ...this.state.InsertEditCompany };
        InsertEditCompany.HasWhitelabeling = event.target.checked;

        this.setState({ InsertEditCompany });
    }

    onToggleShowAddEmployeesModal = ({ ShowAddEmployeesModal, ShowAddEmployeesModal_CompanyId = null, ShowAddEmployeesModal_CompanyName = '' }) => {
        this.setState({ ShowAddEmployeesModal, ShowAddEmployeesModal_CompanyId, ShowAddEmployeesModal_CompanyName, ShowAddEmployeesModal_Emails: [], ShowAddEmployeesModal_TrainerEmail: '' });
    }

    onToggleShowInsertEditCompanyModal = ({ InsertEditCompany = {}, ShowInsertEditCompanyModal }) => {
        var LoadCompanyDetails = false;
        // Editing a company, so get company details
            if (!!+InsertEditCompany.CompanyId) LoadCompanyDetails = true;

        InsertEditCompany = { CompanyId: null, CompanyName: '', Employees: [], HasWhitelabeling: false, InviteLink: '', OriginalName: '', PendingEmployees: [], ...InsertEditCompany };

        this.setState({ InsertEditCompany, ShowInsertEditCompanyModal, ShowInsertEditCompanyModal_EmployeeType: 'Active' }, () => {
            if (LoadCompanyDetails) this.props.GetCompany({ CompanyId: InsertEditCompany.CompanyId }).then(({ Company }) => {
                this.setState({ InsertEditCompany: { ...Company, OriginalName: Company.CompanyName } });
            })
        });
    }

    onUploadEmployees = event => {
        if (event.target.files.length) {
            var EmployeesFile = event.target.files[0];

            this.props.UpdateCompanyEmployees({ EmployeesFile }).then(() => {
                this.onToggleShowInsertEditCompanyModal({ ShowInsertEditCompanyModal: false });
                // this.onGetCompanies();

                toast.success('Processing started. Email will be sent upon completion', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });

            event.target.value = null;
        }
    }

    renderAddEmployeesModal = () => {
        var { t } = this.props;
        var { TryingAddCompanyEmployees } = this.props;
        var { ShowAddEmployeesModal, ShowAddEmployeesModal_TrainerEmail } = this.state;

        if (ShowAddEmployeesModal) {
            return (
                <Info
                    BottomButton="submit"
                    BottomButtonOnClick={this.onAddCompanyEmployees}
                    BottomButtonDisabled={!ShowAddEmployeesModal_TrainerEmail}
                    BottomButtonText={t('_save')}
                    InformationRenderer={this.renderAddEmployeesModal_Information}
                    Loading={(TryingAddCompanyEmployees)}
                    OnHide={() => this.onToggleShowAddEmployeesModal({ ShowAddEmployeesModal: false })}
                    Show={ShowAddEmployeesModal}
                />
            );
        }
    }

    renderAddEmployeesModal_Information = () => {
        var { t } = this.props;
        var { Companies, ShowAddEmployeesModal_CompanyId, ShowAddEmployeesModal_CompanyName, ShowAddEmployeesModal_Emails, ShowAddEmployeesModal_TrainerEmail } = this.state;

        return (
            <Styles.InsertEditCompanyModalContainer className="InsertEditCompanyModalContainer">
                <PageText FontFamily="medium" FontSize="medium-3" JustifyContent="center" NoMargin Text="Add Employees" TextAlign="center" />

                <Spacer Size="medium" />

                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text="Choose company" TextAlign="center" />

                <Select
                    getOptionLabel={option => option.CompanyName}
                    getOptionValue={option => option.CompanyId}
                    onChange={this.onChangeAddEmployees_Company}
                    options={Companies}
                    placeholder="Companies"
                    styles={DropdownStyles}
                    value={ShowAddEmployeesModal_CompanyId && { CompanyId: ShowAddEmployeesModal_CompanyId, CompanyName: ShowAddEmployeesModal_CompanyName }}
                />

                <Spacer Size="medium" />

                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text="Enter DHC email to preregister any new users" TextAlign="center" />

                <Spacer Size="extra-small" />

                <Styles.SearchCompaniesInput
                    AutoFocus
                    FontSize="medium-1"
                    NoLabel
                    NoMargin
                    OnChange={this.onChangeTrainerEmail}
                    Placeholder="DHC email"
                    Size="medium"
                    Type="text"
                    Value={ShowAddEmployeesModal_TrainerEmail}
                />

                <Spacer Size="medium" />

                {
                    ShowAddEmployeesModal_Emails.map(({ Email, Valid }, EmailIndex) => {
                        return (
                            <Styles.InputContainer className="InputContainer" key={EmailIndex}>
                                <Styles.InputInnerContainer className="InputInnerContainer">
                                    <Styles.StyledInput FontSize="medium-2" NoLabel NoMargin OnBlur={() => this.onBlurEmail(EmailIndex)} OnChange={event => this.onChangeEmail({ event, EmailIndex })} Placeholder={t('email_with_first_char_uppercase')} Size="medium" Type="text" Value={Email} />

                                    {
                                        EmailIndex !== 0 ?
                                        <Styles.StyledDeleteIcon ImageSrc={RedX} OnClick={() => this.onDeleteEmail(EmailIndex)} />
                                    :
                                        <div />
                                    }
                                </Styles.InputInnerContainer>

                                {!!Email.length && !Valid && <PageText FontColor="red-bittersweet" FontFamily="medium-italic" FontSize="small" JustifyContent="flex-start" NoMargin Text={t('provide_valid_email_address')} TextAlign="left" />}
                            </Styles.InputContainer>           
                        );
                    })
                }

                <Spacer Size="extra-small" />

                <ButtonGroup
                    Buttons={[{ BackgroundGradient: "gradient1", Border: 'none', Color: 'white', ColorHover: 'white', Icon: Plus, IconPosition: 'left', IconSize: { Height: '20px', Width: '20px' }, OnClick: this.onAddEmail, Title: "Add New Employee Email" }]}
                    ButtonWidth="fit-content"
                    NotTouching
                />
            </Styles.InsertEditCompanyModalContainer>
        );
    }

    renderCompanies = () => {
        var { Companies, SearchCompanyName } = this.state;

        if (SearchCompanyName) {
            SearchCompanyName = SearchCompanyName.toLowerCase();
            Companies = Companies.filter(({ CompanyName }) => CompanyName.toLowerCase().includes(SearchCompanyName));
        }

        return (
            <Styles.CompaniesContainer className="CompaniesContainer">
                <Styles.CompaniesHeaderRow className="CompaniesHeaderRow">
                    <Styles.CompaniesColumnContainer className="CompaniesColumnContainer">
                        <PageText FontFamily="semibold" FontSize="small" JustifyContent="center" NoMargin Text="CompanyId" TextAlign="center" />
                    </Styles.CompaniesColumnContainer>
                    <Styles.CompaniesColumnContainer className="CompaniesColumnContainer">
                        <PageText FontFamily="semibold" FontSize="small" JustifyContent="center" NoMargin Text="Name" TextAlign="center" />
                    </Styles.CompaniesColumnContainer>
                    <Styles.CompaniesColumnContainer className="CompaniesColumnContainer">
                        <PageText FontFamily="semibold" FontSize="small" JustifyContent="center" NoMargin Text="Has White Labeling" TextAlign="center" />
                    </Styles.CompaniesColumnContainer>
                    <Styles.CompaniesColumnContainer className="CompaniesColumnContainer">
                        <PageText FontFamily="semibold" FontSize="small" JustifyContent="center" NoMargin Text="Active Employees" TextAlign="center" />
                    </Styles.CompaniesColumnContainer>
                    <Styles.CompaniesColumnContainer className="CompaniesColumnContainer">
                        <PageText FontFamily="semibold" FontSize="small" JustifyContent="center" NoMargin Text="Pending Employees" TextAlign="center" />
                    </Styles.CompaniesColumnContainer>
                    <Styles.CompaniesColumnContainer className="CompaniesColumnContainer">
                        <PageText FontFamily="semibold" FontSize="small" JustifyContent="center" NoMargin Text="Created Date" TextAlign="center" />
                    </Styles.CompaniesColumnContainer>
                </Styles.CompaniesHeaderRow>

                {
                    Companies.map(Company => {
                        var { CompanyId, CompanyName, CreatedDate, HasWhitelabeling, NumEmployees, NumEmployees_Pending } = Company;

                        return (
                            <Styles.CompaniesBodyRow key={CompanyId} className="CompaniesBodyRow" onClick={() => this.onToggleShowInsertEditCompanyModal({ InsertEditCompany: { ...Company, OriginalName: Company.CompanyName }, ShowInsertEditCompanyModal: true })}>
                                <Styles.CompaniesColumnContainer className="CompaniesColumnContainer">
                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={`${CompanyId}`} TextAlign="center" />
                                </Styles.CompaniesColumnContainer>
                                <Styles.CompaniesColumnContainer className="CompaniesColumnContainer">
                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={CompanyName} TextAlign="center" />
                                </Styles.CompaniesColumnContainer>
                                <Styles.CompaniesColumnContainer className="CompaniesColumnContainer">
                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={!!+HasWhitelabeling ? 'Yes' : 'No'} TextAlign="center" />
                                </Styles.CompaniesColumnContainer>
                                <Styles.CompaniesColumnContainer className="CompaniesColumnContainer">
                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={`${NumEmployees}`} TextAlign="center" />
                                </Styles.CompaniesColumnContainer>
                                <Styles.CompaniesColumnContainer className="CompaniesColumnContainer">
                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={`${NumEmployees_Pending}`} TextAlign="center" />
                                </Styles.CompaniesColumnContainer>
                                <Styles.CompaniesColumnContainer className="CompaniesColumnContainer">
                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={new Date(CreatedDate).toLocaleString()} TextAlign="center" />
                                </Styles.CompaniesColumnContainer>
                            </Styles.CompaniesBodyRow>
                        );
                    })
                }
            </Styles.CompaniesContainer>
        );
    }

    renderEmployees = () => {
        var { t } = this.props;

        if (this.props.TryingGetCompany) {
            return (
                <>
                    <Spacer Size="medium" />
                    
                    <PageText FontFamily="medium-italic" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('loading')} TextAlign="center" />
                </>
            );
        }

        var { InsertEditCompany: { CompanyId, Employees, PendingEmployees }, ShowInsertEditCompanyModal_EmployeeType } = this.state;

        return (
            <>
                <Spacer Size="medium" />

                <PageText FontFamily="semibold" FontSize="medium-3" JustifyContent="center" NoMargin Text={t('employees')} TextAlign="center" />

                {
                    ([ 1, 2, 6, 18097 ].indexOf(+this.props.UserDetails.UserId) > -1) &&
                    <>
                        <Spacer Size="small" />

                        <ButtonGroup
                            Buttons={[{ BackgroundColor: 'white-concrete', BackgroundColorHover: 'white-concrete-hover', Border: 'none', Color: 'black', ColorHover: 'black', FontFamily: 'semibold', Icon: EditIcon, IconPosition: 'left', IsInput: true, InputProps: { accept: "csv,.csv", id: "PendingEmployees", type: "file", onChange: this.onUploadEmployees }, Title: t('Upload') }]}
                            ButtonWidth="fit-content"
                            ContainerWidth="fit-content"
                        />
                    </>
                }

                <Spacer Size="small" />

                <ButtonGroup
                    Buttons={[
                        { BackgroundColor: ShowInsertEditCompanyModal_EmployeeType === 'Active' ? 'blue-abel' : 'white-concrete', BackgroundColorHover: ShowInsertEditCompanyModal_EmployeeType === 'Active' ? 'blue-abel-hover' : 'white-concrete-hover', Color: ShowInsertEditCompanyModal_EmployeeType === 'Active' ? 'white' : 'black', ColorHover: ShowInsertEditCompanyModal_EmployeeType === 'Active' ? 'white' : 'black', OnClick: () => this.onToggleEmployeeType('Active'), Title: t('active') },
                        { BackgroundColor: ShowInsertEditCompanyModal_EmployeeType === 'Pending' ? 'blue-abel' : 'white-concrete', BackgroundColorHover: ShowInsertEditCompanyModal_EmployeeType === 'Pending' ? 'blue-abel-hover' : 'white-concrete-hover', Color: ShowInsertEditCompanyModal_EmployeeType === 'Pending' ? 'white' : 'black', ColorHover: ShowInsertEditCompanyModal_EmployeeType === 'Pending' ? 'white' : 'black', OnClick: () => this.onToggleEmployeeType('Pending'), Title: t('pending') }
                    ]}
                    ButtonWidth="fit-content"
                />

                <Styles.EmployeesSectionContainer>
                    {/* {
                        ShowInsertEditCompanyModal_EmployeeType === 'Active' &&
                        <>
                            <Spacer Size="extra-small" />

                            <ButtonGroup
                                Buttons={[{ BackgroundGradient: "gradient1", Border: 'none', Color: 'white', ColorHover: 'white', Icon: Plus, IconPosition: 'left', IconSize: { Height: '20px', Width: '20px' }, OnClick: () => this.onToggleShowAddEmployeesModal({ ShowAddEmployeesModal: true, ShowAddEmployeesModal_CompanyId: CompanyId, ShowAddEmployeesModal_CompanyName: CompanyName }), Title: t('add_employees') }]}
                                ButtonWidth="fit-content"
                                ContainerWidth="fit-content"
                            />
                        </>
                    } */}

                    {
                        (
                            (ShowInsertEditCompanyModal_EmployeeType === 'Active' && Employees.length === 0) ||
                            (ShowInsertEditCompanyModal_EmployeeType === 'Pending' && PendingEmployees.length === 0)
                        ) &&
                        <>
                            <Spacer Size="small" />

                            <PageText FontFamily="medium-italic" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('no_employees')} TextAlign="center" />
                        </>
                    }

                {/* Active Employees */}
                    {
                        (ShowInsertEditCompanyModal_EmployeeType === 'Active' && Employees.length > 0) &&
                        <>
                            <Spacer Size="extra-small" />

                            <ButtonGroup
                                Buttons={[{ BackgroundColor: "blue-abel", BackgroundColorHover: 'blue-abel-hover', Border: 'none', Color: 'white', ColorHover: 'white', Icon: DownloadIcon, IconPosition: 'left', IconSize: { Height: '20px', Width: '20px' }, OnClick: () => this.onDownloadEmployees({ CompanyId, EmployeeType: 'Active' }), Title: t('download') }]}
                                ButtonWidth="fit-content"
                                ContainerWidth="fit-content"
                            />

                            <Spacer Size="extra-small" />

                            <Styles.EmployeesContainer className="EmployeesContainer">
                                <Styles.EmployeesHeaderRow className="EmployeesHeaderRow">
                                    <Styles.EmployeesColumnContainer className="EmployeesColumnContainer">
                                        <PageText FontFamily="semibold" FontSize="small" JustifyContent="center" NoMargin Text="Employee" TextAlign="center" />
                                    </Styles.EmployeesColumnContainer>
                                    <Styles.EmployeesColumnContainer className="EmployeesColumnContainer">
                                        <PageText FontFamily="semibold" FontSize="small" JustifyContent="center" NoMargin Text="DHC" TextAlign="center" />
                                    </Styles.EmployeesColumnContainer>
                                    <Styles.EmployeesColumnContainer className="EmployeesColumnContainer">
                                        <PageText FontFamily="semibold" FontSize="small" JustifyContent="center" NoMargin Text="Account status" TextAlign="center" />
                                    </Styles.EmployeesColumnContainer>
                                    <Styles.EmployeesColumnContainer className="EmployeesColumnContainer">
                                        <PageText FontFamily="semibold" FontSize="small" JustifyContent="center" NoMargin Text="Created Date" TextAlign="center" />
                                    </Styles.EmployeesColumnContainer>
                                </Styles.EmployeesHeaderRow>

                                {
                                    Employees.map(Company => {
                                        var { CreatedDate, Email, FullName, IsPreRegistered, TrainerEmail, TrainerFullName, UserId } = Company;

                                        return (
                                            <Styles.EmployeesBodyRow key={UserId} className="EmployeesBodyRow">
                                                <Styles.EmployeesColumnContainer className="EmployeesColumnContainer">
                                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={`${FullName}`} TextAlign="center" />
                                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={`${Email}`} TextAlign="center" />
                                                </Styles.EmployeesColumnContainer>
                                                <Styles.EmployeesColumnContainer className="EmployeesColumnContainer">
                                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={`${TrainerFullName}`} TextAlign="center" />
                                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={`${TrainerEmail}`} TextAlign="center" />
                                                </Styles.EmployeesColumnContainer>
                                                <Styles.EmployeesColumnContainer className="EmployeesColumnContainer">
                                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={!!+IsPreRegistered ? 'PreRegistered' : 'Registered'} TextAlign="center" />
                                                </Styles.EmployeesColumnContainer>
                                                <Styles.EmployeesColumnContainer className="EmployeesColumnContainer">
                                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={new Date(CreatedDate).toLocaleString()} TextAlign="center" />
                                                </Styles.EmployeesColumnContainer>
                                            </Styles.EmployeesBodyRow>
                                        );
                                    })
                                }
                            </Styles.EmployeesContainer>
                        </>
                    }

                {/* Pending Employees */}
                    {
                        (ShowInsertEditCompanyModal_EmployeeType === 'Pending' && PendingEmployees.length > 0) &&
                        <>
                            <Spacer Size="extra-small" />

                            <ButtonGroup
                                Buttons={[{ BackgroundColor: "blue-abel", BackgroundColorHover: 'blue-abel-hover', Border: 'none', Color: 'white', ColorHover: 'white', Icon: DownloadIcon, IconPosition: 'left', IconSize: { Height: '20px', Width: '20px' }, OnClick: () => this.onDownloadEmployees({ CompanyId, EmployeeType: 'Pending' }), Title: t('download') }]}
                                ButtonWidth="fit-content"
                                ContainerWidth="fit-content"
                            />

                            <Spacer Size="extra-small" />

                            <Styles.EmployeesContainer className="EmployeesContainer">
                                <Styles.EmployeesHeaderRowPending className="EmployeesHeaderRowPending">
                                    <Styles.EmployeesColumnContainer className="EmployeesColumnContainer">
                                        <PageText FontFamily="semibold" FontSize="small" JustifyContent="center" NoMargin Text="Employee" TextAlign="center" />
                                    </Styles.EmployeesColumnContainer>
                                    <Styles.EmployeesColumnContainer className="EmployeesColumnContainer">
                                        <PageText FontFamily="semibold" FontSize="small" JustifyContent="center" NoMargin Text="Chosen DHC" TextAlign="center" />
                                    </Styles.EmployeesColumnContainer>
                                    <Styles.EmployeesColumnContainer className="EmployeesColumnContainer">
                                        <PageText FontFamily="semibold" FontSize="small" JustifyContent="center" NoMargin Text="Algorithm DHC" TextAlign="center" />
                                    </Styles.EmployeesColumnContainer>
                                    <Styles.EmployeesColumnContainer className="EmployeesColumnContainer">
                                        <PageText FontFamily="semibold" FontSize="small" JustifyContent="center" NoMargin Text="Created Date" TextAlign="center" />
                                    </Styles.EmployeesColumnContainer>
                                </Styles.EmployeesHeaderRowPending>

                                {
                                    PendingEmployees.map(Company => {
                                        var { AlgoDHC, ChosenDHC, CreatedDate, Employee, UserId } = Company;

                                        return (
                                            <Styles.EmployeesBodyRowPending key={UserId} className="EmployeesBodyRowPending">
                                                <Styles.EmployeesColumnContainer className="EmployeesColumnContainer">
                                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={`${Employee}`} TextAlign="center" />
                                                </Styles.EmployeesColumnContainer>
                                                <Styles.EmployeesColumnContainer className="EmployeesColumnContainer">
                                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={`${ChosenDHC}`} TextAlign="center" />
                                                </Styles.EmployeesColumnContainer>
                                                <Styles.EmployeesColumnContainer className="EmployeesColumnContainer">
                                                <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={`${AlgoDHC}`} TextAlign="center" />
                                                </Styles.EmployeesColumnContainer>
                                                <Styles.EmployeesColumnContainer className="EmployeesColumnContainer">
                                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={new Date(CreatedDate).toLocaleString()} TextAlign="center" />
                                                </Styles.EmployeesColumnContainer>
                                            </Styles.EmployeesBodyRowPending>
                                        );
                                    })
                                }
                            </Styles.EmployeesContainer>
                        </>
                    }
                </Styles.EmployeesSectionContainer>
            </>
        );
    }

    renderInsertEditCompanyModel = () => {
        var { t } = this.props;
        var { TryingDeleteCompanyEmployees, TryingInsertEditCompany } = this.props;
        var { InsertEditCompany, ShowAddEmployeesModal, ShowInsertEditCompanyModal } = this.state;

        if (ShowInsertEditCompanyModal && !ShowAddEmployeesModal) {
            return (
                <Info
                    BottomButton="submit"
                    BottomButtonDisabled={!InsertEditCompany.CompanyName}
                    BottomButtonOnClick={() => this.onInsertEditCompany(0)}
                    BottomButtonText={t('_save')}
                    InformationRenderer={this.renderInsertEditCompanyModel_Information}
                    Loading={(TryingDeleteCompanyEmployees || TryingInsertEditCompany)}
                    OnHide={() => this.onToggleShowInsertEditCompanyModal({ ShowInsertEditCompanyModal: false })}
                    Show={ShowInsertEditCompanyModal}
                />
            );
        }
    }

    renderInsertEditCompanyModel_Information = () => {
        var { t } = this.props;
        var { CompanyId, CompanyName, HasWhitelabeling, InviteLink, OriginalName } = this.state.InsertEditCompany;

        return (
            <>
                {(this.props.TryingDownloadEmployees) && <Loading />}

                <Styles.InsertEditCompanyModalContainer className="InsertEditCompanyModalContainer">
                    <PageText FontFamily="medium" FontSize="medium-3" JustifyContent="center" NoMargin Text={t('company_detail')} TextAlign="center" />

                    <Spacer Size="medium" />

                    <Styles.SearchAndAddContainer className="SearchAndAddContainer">
                        <Styles.SearchCompaniesInput
                            FontSize="medium-1"
                            NoLabel
                            NoMargin
                            OnChange={this.onChangeCompanyName}
                            Placeholder="Company name"
                            Size="medium"
                            Type="text"
                            Value={CompanyName}
                        />

                        <Styles.ToggleContainer className="ToggleContainer">
                            <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('has_white_labeling')} TextAlign="left"  />

                            <Toggle
                                Checked={!!+HasWhitelabeling}
                                OffLabel="No"
                                OnChange={this.onToggleHasWhitelabeling}
                                OneLabel="after"
                                OnLabel="Yes"
                            />
                        </Styles.ToggleContainer>
                    </Styles.SearchAndAddContainer>

                    {
                        !!+CompanyId &&
                        <>
                            {OriginalName !== CompanyName && <PageText FontColor="red-bittersweet" FontFamily="medium-italic" FontSize="small" JustifyContent="flex-start" NoMargin Text={t('changing_name_changes_invite_link')} TextAlign="let" />}

                            <Spacer Size="extra-extra-small" />

                            {this.renderInviteLink(InviteLink)}

                            {this.renderEmployees()}
                        </>
                    }
                </Styles.InsertEditCompanyModalContainer>
            </>
        );
    }

    renderInviteLink = InviteLink => {
        var { t } = this.props;

        var InviteLinkUrl = `${COMPANY_INVITE_URL}?code=${InviteLink}`;

        return (
            <Styles.InviteLinkContainer className="InviteLinkContainer">
                <MultiText TextAlign="left"
                    Texts={[
                        { FontFamily: 'medium', FontSize: 'medium-1', Text: `${t('company_invite_link_title')}:` },
                        { FontColor: 'blue-abel', FontFamily: 'medium-italic', FontSize: 'medium-1', Text: InviteLinkUrl }
                    ]}
                />

                <ButtonGroup
                    Buttons={[
                        { BackgroundColor: 'blue-astronaut', BackgroundColorHover: 'blue-astronaut-hover', Border: 'none', Color: 'white', ColorHover: 'white', FontFamily: 'medium', Icon: Copy, IconPosition: 'left', IconSize: { Height: '15px', Width: '15px' }, OnClick: () => this.onCopyInviteLink(InviteLinkUrl), Size: "small", TextAlign: 'left', Title: t('copy') },
                        { BackgroundColor: 'red-bittersweet', BackgroundColorHover: 'red-bittersweet-hover', Border: 'none', Color: 'white', ColorHover: 'white', FontFamily: 'medium', Icon: EditIcon, IconPosition: 'left', IconSize: { Height: '15px', Width: '15px' }, OnClick: this.onResetInviteLink, Size: "small", TextAlign: 'left', Title: t('reset') }
                        // { BackgroundColor: 'blue-abel', BackgroundColorHover: 'blue-abel-hover', Border: 'none', Color: 'white', ColorHover: 'white', FontFamily: 'semibold', FontSize: 'small', Icon: SwapIcon, IconPosition: 'left', IconSize: { Height: '15px', Width: '15px' }, OnClick: OnToggleShowChangeExerciseModal, Size: 'small', TextAlign: 'left', Title: t('change_exercise') }
                    ]}
                    ButtonWidth='fit-content'
                    NotTouching
                />
            </Styles.InviteLinkContainer>
        );
    }

    render() {
        var { t } = this.props;
        var { TryingGetCompanies, TryingInsertEditCompany } = this.props;
        var { SearchCompanyName, ShowInsertEditCompanyModal } = this.state;

        var DownloadUploadButtons = [{ BackgroundColor: "blue-abel", BackgroundColorHover: 'blue-abel-hover', Border: 'none', Color: 'white', ColorHover: 'white', Icon: DownloadIcon, IconPosition: 'left', IconSize: { Height: '20px', Width: '20px' }, OnClick: () => this.onDownloadEmployees({ CompanyId: 0, EmployeeType: 'Active' }), Title: t('download') }];
        if ([ 1, 2, 6, 18097 ].indexOf(+this.props.UserDetails.UserId) > -1) DownloadUploadButtons.push({ BackgroundColor: 'white-concrete', BackgroundColorHover: 'white-concrete-hover', Border: 'none', Color: 'black', ColorHover: 'black', FontFamily: 'semibold', Icon: EditIcon, IconPosition: 'left', IsInput: true, InputProps: { accept: "csv,.csv", id: "PendingEmployees", type: "file", onChange: this.onUploadEmployees }, Title: t('Upload') });

        return (
            <>
                {(TryingGetCompanies || (TryingInsertEditCompany && !ShowInsertEditCompanyModal)) && <Loading />}

                <Page>
                    <PageText FontFamily="medium" FontSize="large" JustifyContent="center" NoMargin Text="Companies" TextAlign="center" />

                    <Spacer Size="medium" />

                    <Styles.SearchAndAddContainer className="SearchAndAddContainer">
                        <Styles.SearchCompaniesInput
                            FontSize="medium-1"
                            NoLabel
                            NoMargin
                            OnChange={this.onChangeSearchCompanyName}
                            Placeholder="Search by company name"
                            Size="medium"
                            Type="text"
                            Value={SearchCompanyName}
                        />

                        <ButtonGroup
                            Buttons={[{ BackgroundGradient: "gradient1", Border: 'none', Color: 'white', ColorHover: 'white', Icon: Plus, IconPosition: 'left', IconSize: { Height: '20px', Width: '20px' }, OnClick: () => this.onToggleShowInsertEditCompanyModal({ ShowInsertEditCompanyModal: true }), Title: t('new_company') }]}
                            ButtonWidth="fit-content"
                            ContainerWidth="fit-content"
                        />
                    </Styles.SearchAndAddContainer>

                    <Spacer Size="medium" />

                    {this.renderAddEmployeesModal()}

                    <ButtonGroup
                        Buttons={DownloadUploadButtons}
                        ButtonWidth="fit-content"
                        ContainerWidth="fit-content"
                    />

                    <Spacer Size="medium" />

                    {this.renderCompanies()}
                </Page>

                {this.renderInsertEditCompanyModel()}
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        UserDetails: state.Auth.UserDetails,

        TryingAddCompanyEmployees: state.Companies.TryingAddCompanyEmployees,
        TryingAddCompanyEmployeesError: state.Companies.TryingAddCompanyEmployeesError,
        TryingDeleteCompanyEmployees: state.Companies.TryingDeleteCompanyEmployees,
        TryingDeleteCompanyEmployeesError: state.Companies.TryingDeleteCompanyEmployeesError,
        TryingDownloadEmployees: state.Companies.TryingDownloadEmployees,
        TryingDownloadEmployeesError: state.Companies.TryingDownloadEmployeesError,
        TryingGetCompanies: state.Companies.TryingGetCompanies,
        TryingGetCompaniesError: state.Companies.TryingGetCompaniesError,
        TryingGetCompany: state.Companies.TryingGetCompany,
        TryingGetCompanyError: state.Companies.TryingGetCompanyError,
        TryingInsertEditCompany: state.Companies.TryingInsertEditCompany,
        TryingInsertEditCompanyError: state.Companies.TryingInsertEditCompanyError
    };
};

export default withTranslation()(connect(mapStateToProps, { AddCompanyEmployees, DeleteCompanyEmployees, DownloadCompanyEmployees, GetCompanies, GetCompany, InsertEditCompany, UpdateCompanyEmployees } )(CompaniesComponent));